import { Formik } from "formik";
import React, { useState } from "react";
import { IoIosArrowDropleft } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../constants/common";
import { REMOVE_REDIRECT_PATH } from "../../constants/contexConstant";
import { emailRegEx, phoneRegEx } from "../../constants/regularExpression";
import { LOGIN, SIGNUP_CONSUMER_ENDPOINT } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { handleRedirection } from "../../helpers/routingHelper";
import { handleResentOTP } from "../../services/apiRequest";
import auth from "../../services/auth";
import request from "../../services/request";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import FormInput from "../../shared/ui/Form/FormInput";
import { signupWithPasswordValidator } from "../../shared/ui/Form/schema";
import OTP from "../../shared/ui/OTP";

const validateSignupForm = (formData, getOtp) => {
    const newErrors = {};
    if (!formData.Name.trim()) {
        newErrors.Name = "Name is required";
    }

    if (!formData.email.trim()) {
        newErrors.email = "Email is required";
    } else if (!emailRegEx.test(formData.email)) {
        newErrors.email = "Invalid Email";
    }
    if (!formData.phoneNumber) {
        newErrors.phoneNumber = "Phone Number is required";
    } else if (!phoneRegEx.test(formData.phoneNumber)) {
        newErrors.phoneNumber = "Invalid Phone Number ";
    }
    if (!getOtp) {
        if (!formData.password.trim()) {
            newErrors.password = "Password is required";
        }
        if (!formData.confirmPassword.trim()) {
            newErrors.confirmPassword = "Password is required";
        } else if (formData.confirmPassword !== formData.password) {
            newErrors.confirmPassword = "Password does not match";
        }
    } else {
        if (!formData.otp.trim()) {
            newErrors.otp = "OTP is required";
        }
    }
    return newErrors;
};

const SignUpPage = ({ handleLogIn }) => {
    const { state, dispatch } = useContextState();
    const history = useHistory();

    const [formData, setFormData] = useState({
        otp: "",
    });

    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });
    };

    const handleSubmit = async (values, actions, mode) => {
        setLoading(true);
        if (mode) {
            handleResentOTP(mode, values.username, () => null, dispatch);
            return;
        } else {
            try {
                let reqData = {
                    name: values.name,
                    email: values.email,
                    phone: `91${values.mobile}`,
                    referralCode: null,
                    password: values.password,
                };
                if (otp) {
                    reqData.otp = formData.otp;
                }
                let endpoint = SIGNUP_CONSUMER_ENDPOINT;
                await request.post({
                    endpoint,
                    body: reqData,
                });

                if (otp) {
                    await auth.login({
                        username: values.email,
                        password: values.password,
                        otpEnable: false,
                    });

                    const profile = await auth.getUserProfile(dispatch);
                    let status = profile?.data?.investor_profile?.plant?.status;
                    const roles = profile?.data?.investor_profile?.roles;

                    handleRedirection(state, history, roles, status);
                } else {
                    setOtp((prev) => !prev);
                }

                dispatch({
                    type: REMOVE_REDIRECT_PATH,
                });
                setLoading(false);

                handleLogIn();
            } catch (err) {
                setLoading(false);
                toast.error(err || ERROR_MSG);
            }
        }
    };
    const [otp, setOtp] = useState(false);
    const initialValues = {
        name: "",
        password: "",
        email: "",
        mobile: "",
        confirm: "",
    };
    return (
        <div>
            <div className="flex items-center gap-2 mb-2">
                <div
                    className="text-3xl xl:font-light "
                    onClick={() => (otp ? setOtp(false) : history.goBack())}
                >
                    <IoIosArrowDropleft />
                </div>
                <H4 className="font-semibold">Create your account</H4>
            </div>
            <div className="flex flex-col gap-1.5">
                <Formik
                    initialValues={initialValues}
                    validationSchema={signupWithPasswordValidator}
                    onSubmit={handleSubmit}
                >
                    {({
                        handleSubmit,
                        isSubmitting,
                        resetForm,
                        values,
                        isValid,
                        dirty,
                    }) => (
                        <>
                            <FormInput
                                label={"Full Name"}
                                name={"name"}
                                type={"text"}
                                required
                            />
                            <div>
                                <FormInput
                                    label={"Mobile"}
                                    name={"mobile"}
                                    type={"text"}
                                    required
                                />
                                <span className="text-xs text-gray-500">
                                    An OTP will be sent to your phone number for
                                    verification
                                </span>
                            </div>
                            <FormInput
                                label={"Email"}
                                name={"email"}
                                type={"text"}
                                required
                            />
                            <FormInput
                                label={"Password"}
                                name={"password"}
                                type={"text"}
                                required
                            />
                            <FormInput
                                label={"Confirm Password"}
                                name={"confirm"}
                                type={"text"}
                                required
                            />
                            {otp && (
                                <OTP
                                    otp={formData.otp}
                                    onChange={handleChange}
                                    error={errors?.otp ? true : false}
                                    errorMessage={errors.otp}
                                    handleResentOTP={(mode) =>
                                        handleSubmit(
                                            values,
                                            { resetForm },
                                            mode
                                        )
                                    }
                                />
                            )}
                            <Button
                                className={
                                    "bg-black text-white mt-1 !rounded-[3rem] w-full"
                                }
                                type="submit"
                                disabled={isSubmitting || !(isValid && dirty)}
                                isLoading={isSubmitting}
                                onClick={handleSubmit}
                            >
                                Continue
                            </Button>
                        </>
                    )}
                </Formik>
            </div>
            <div className="flex flex-col gap-2 mt-1">
                <div className="flex items-center justify-end mb-2">
                    <P className="flex items-center gap-0.2">
                        Already have account?
                        <span
                            className="text-blue underline"
                            role="button"
                            onClick={() => history.push(LOGIN)}
                        >
                            Sign in
                        </span>
                    </P>
                </div>
            </div>
        </div>
    );
};

export default SignUpPage;
