import React, { useEffect, useState } from "react";
import { ERROR_MSG } from "../../constants/common";
import { SET_ERROR } from "../../constants/contexConstant";
import { getCurrencyAmount } from "../../helpers/helpers";
import request from "../../services/request";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import CalculatorRangeSlider from "../project/mobile/project-details/CalculatorRangeSlider";

const Returns = ({
    SetReturns,
    dispatch,
    returns,
    onNext,
    planDetails,
    subsidy,
    loadPlanMeta = () => {},
    code,
}) => {
    const rangeValue = {
        min: 10,
        max: 24,
    };
    const [initialReturns, setInitialReturns] = useState(
        planDetails?.planMeta?.expectedReturns
    );
    const [investor, setInvestor] = useState(0);
    const [maintain, setMaintain] = useState(0);
    const investmentAmount = subsidy
        ? planDetails?.plantMeta?.solarPlantCostPerUnitWithSubsidy
        : planDetails?.plantMeta?.solarPlantCostPerUnitWithoutSubsidy;

    useEffect(() => {
        const fetchData = async () => {
            try {
                let endpoint = `/community/lease/return?code=${code}`;
                const res = await request.authPost({
                    endpoint,
                    body: {
                        expectedReturns: returns.toLocaleString(),
                        availingSubsidy: subsidy,
                    },
                });

                setInvestor(res?.data?.data?.investorPayput);
                setMaintain(res?.data?.data?.maintenanceCharges);
            } catch (error) {
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        };

        fetchData();
    }, [returns]);

    const handleSubmit = async () => {
        try {
            let endpoint = `/community/lease/plan/save?code=${code}`;
            const res = await request.authPost({
                endpoint,
                body: {
                    expectedReturns: returns.toLocaleString(),
                    availingSubsidy: subsidy,
                },
            });
            loadPlanMeta();
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
        }
    };
    const totalAmt = maintain + investor;

    const savingsPercentage =
        ((planDetails?.plantMeta?.consumerBill - totalAmt) /
            planDetails?.plantMeta?.consumerBill) *
        100;

    const savings = planDetails?.plantMeta?.consumerBill - totalAmt;
    const isButtonDisabled =
        planDetails?.investmentMeta &&
        returns === initialReturns &&
        planDetails?.planMeta?.availingSubsidy === subsidy;
    return (
        <div className="pb-5">
            <div className=" bg-gray-50 border border-gray-400 rounded-3xl my-2 p-2">
                <P>Investment Details</P>
                <div className="flex  justify-between gap-1.2 ">
                    <div className="flex-col items-start gap-2 mt-1">
                        <H4 className="font-vietnam text-sm font-medium">
                            {getCurrencyAmount(investmentAmount)}
                        </H4>
                        <p className="text-xs   text-gray-600">Fund Required</p>
                    </div>
                    <div
                        className={`bg-gray-400 w-0.1 min-h-[7rem]  max-h-[10rem]
                `}
                    />
                    <div className="flex-col items-center justify-center gap-2 mt-1">
                        <H4 className="font-vietnam text-sm font-medium">
                            {returns}%
                        </H4>
                        <p className="text-xs  text-gray-600">
                            Investor’s Return
                        </p>
                    </div>
                    <div
                        className={`bg-gray-400 w-0.1 min-h-[7rem]  max-h-[10rem]
                `}
                    />
                    <div className="flex-col justify-center items-center gap-2 mt-1">
                        <H4 className="font-vietnam text-sm font-medium">
                            {savingsPercentage.toPrecision(2)}%
                        </H4>
                        <p className="text-xs  text-gray-600">
                            Society Savings
                        </p>
                    </div>
                </div>
            </div>
            <div className="my-3">
                <div className="flex justify-between items-center my-1">
                    <P>Change Investor Returns</P>
                    <p className="text-sm text-blue-500 text-center mb-1 ">
                        {returns.toLocaleString()}%
                    </p>
                </div>
                <div>
                    <CalculatorRangeSlider
                        min={rangeValue.min}
                        max={rangeValue.max}
                        value={returns}
                        onChange={SetReturns}
                    />
                </div>
                <div className="flex justify-between">
                    <P className="text-gray-550 ">{rangeValue.min}%</P>
                    <P className="text-gray-550">{rangeValue.max}%</P>
                </div>
            </div>
            <div className="p-2 border border-gray-400 rounded-3xl">
                <div className="flex justify-between items-center">
                    <P className="font-medium">Monthly Lease Payment</P>
                    <P className="font-medium">
                        ₹{getCurrencyAmount(totalAmt)}
                    </P>
                </div>
                <div
                    className={`bg-gray-400 h-0.1 w-full my-1
                `}
                />
                <div className="flex justify-between items-center my-1">
                    <P className="font-normal">Monthly Investor Payouts</P>
                    <P className="font-normal">
                        ₹{getCurrencyAmount(investor)}
                    </P>
                </div>
                <div className="flex justify-between items-center">
                    <P className="font-normal">Plant Maintenance Charges</P>
                    <P className="font-normal">
                        ₹{Number(maintain).toLocaleString()}
                    </P>
                </div>
            </div>

            <div className="bg-linear-button my-2 p-1.5 rounded-3xl text-center">
                <P className="font-semibold text-xs xs:text-sm text-white">
                    Society will save monthly ₹{savings} (
                    {savingsPercentage.toPrecision(2)}%)
                </P>
            </div>
            <Button
                className="w-full bg-black-600  text-white mt-2 mb-4 sm:mb-2 !rounded-[3rem]"
                onClick={() => {
                    handleSubmit();
                    onNext(3);
                }}
                disabled={isButtonDisabled}
            >
                {planDetails?.investmentMeta
                    ? " Update Lease Plan"
                    : "Finalise Lease Plan"}
            </Button>
        </div>
    );
};

export default Returns;
