import {
    LEASE_DASHBOARD,
    LEASE_HOME,
    LEASE_INVEST,
    LEASE_PLAN,
    USER_HOME_LABEL,
} from "../../constants/routes";

const tabCommData = [
    {
        id: 1,
        path: LEASE_HOME,
        icon: (
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2.5 12.2039C2.5 9.91549 2.5 8.77128 3.0192 7.82274C3.5384 6.87421 4.48695 6.28551 6.38403 5.10813L8.38403 3.86687C10.3894 2.62229 11.3921 2 12.5 2C13.6079 2 14.6106 2.62229 16.616 3.86687L18.616 5.10812C20.5131 6.28551 21.4616 6.87421 21.9808 7.82274C22.5 8.77128 22.5 9.91549 22.5 12.2039V13.725C22.5 17.6258 22.5 19.5763 21.3284 20.7881C20.1569 22 18.2712 22 14.5 22H10.5C6.72876 22 4.84315 22 3.67157 20.7881C2.5 19.5763 2.5 17.6258 2.5 13.725V12.2039Z"
                    // stroke="#424444"
                    stroke="currentColor"
                    strokeWidth="1.5"
                />
                <path
                    d="M15.5 18H9.5"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
            </svg>
        ),
        name: USER_HOME_LABEL,
    },
    {
        id: 2,
        path: LEASE_PLAN,
        icon: (
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.5 5.75C8.09 5.75 7.75 5.41 7.75 5V2C7.75 1.59 8.09 1.25 8.5 1.25C8.91 1.25 9.25 1.59 9.25 2V5C9.25 5.41 8.91 5.75 8.5 5.75Z"
                    fill="currentColor"
                />
                <path
                    d="M16.5 5.75C16.09 5.75 15.75 5.41 15.75 5V2C15.75 1.59 16.09 1.25 16.5 1.25C16.91 1.25 17.25 1.59 17.25 2V5C17.25 5.41 16.91 5.75 16.5 5.75Z"
                    fill="currentColor"
                />
                <path
                    d="M15.5 11.75H7.5C7.09 11.75 6.75 11.41 6.75 11C6.75 10.59 7.09 10.25 7.5 10.25H15.5C15.91 10.25 16.25 10.59 16.25 11C16.25 11.41 15.91 11.75 15.5 11.75Z"
                    fill="currentColor"
                />
                <path
                    d="M12.5 15.75H7.5C7.09 15.75 6.75 15.41 6.75 15C6.75 14.59 7.09 14.25 7.5 14.25H12.5C12.91 14.25 13.25 14.59 13.25 15C13.25 15.41 12.91 15.75 12.5 15.75Z"
                    fill="currentColor"
                />
                <path
                    d="M15.5 22.75H9.5C3.88 22.75 2.75 20.1 2.75 15.82V9.65C2.75 4.91 4.35 2.98 8.46 2.75H16.5C20.65 2.98 22.25 4.91 22.25 9.65V16C22.25 16.41 21.91 16.75 21.5 16.75C21.09 16.75 20.75 16.41 20.75 16V9.65C20.75 5.29 19.3 4.41 16.46 4.25H8.5C5.7 4.41 4.25 5.29 4.25 9.65V15.82C4.25 19.65 4.98 21.25 9.5 21.25H15.5C15.91 21.25 16.25 21.59 16.25 22C16.25 22.41 15.91 22.75 15.5 22.75Z"
                    fill="currentColor"
                />
                <path
                    d="M15.5 22.75C15.4 22.75 15.31 22.73 15.21 22.69C14.93 22.57 14.75 22.3 14.75 22V19C14.75 16.58 16.08 15.25 18.5 15.25H21.5C21.8 15.25 22.08 15.43 22.19 15.71C22.31 15.99 22.24 16.31 22.03 16.53L16.03 22.53C15.89 22.67 15.7 22.75 15.5 22.75ZM18.5 16.75C16.92 16.75 16.25 17.42 16.25 19V20.19L19.69 16.75H18.5Z"
                    fill="currentColor"
                />
            </svg>
        ),
        name: "Plan",
    },
    {
        id: 3,
        path: LEASE_DASHBOARD,
        icon: (
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.9065 20.2981C4.434 20.2981 4.03175 20.1322 3.69975 19.8004C3.36791 19.4684 3.202 19.0654 3.202 18.5914V8.2069C3.202 7.7329 3.36791 7.32998 3.69975 6.99815C4.03175 6.66615 4.43475 6.50015 4.90875 6.50015H9.12125V4.90765C9.12125 4.43348 9.28716 4.03065 9.619 3.69915C9.951 3.36782 10.3533 3.20215 10.826 3.20215H14.174C14.6467 3.20215 15.049 3.36815 15.381 3.70015C15.7128 4.03198 15.8787 4.4349 15.8787 4.9089V6.50015H20.0912C20.5652 6.50015 20.9682 6.66615 21.3002 6.99815C21.6321 7.32998 21.798 7.7329 21.798 8.2069V18.5914C21.798 19.0654 21.6321 19.4684 21.3002 19.8004C20.9682 20.1322 20.566 20.2981 20.0935 20.2981H4.9065ZM4.90875 18.8991H20.0912C20.1682 18.8991 20.2388 18.8671 20.303 18.8031C20.367 18.739 20.399 18.6684 20.399 18.5914V8.2069C20.399 8.1299 20.367 8.0594 20.303 7.9954C20.2388 7.93123 20.1682 7.89915 20.0912 7.89915H4.90875C4.83175 7.89915 4.76116 7.93123 4.697 7.9954C4.633 8.0594 4.601 8.1299 4.601 8.2069V18.5914C4.601 18.6684 4.633 18.739 4.697 18.8031C4.76116 18.8671 4.83175 18.8991 4.90875 18.8991ZM10.5202 6.50015H14.4797V4.9089C14.4797 4.8319 14.4477 4.7614 14.3837 4.6974C14.3196 4.63323 14.249 4.60115 14.172 4.60115H10.828C10.751 4.60115 10.6804 4.63323 10.6162 4.6974C10.5522 4.7614 10.5202 4.8319 10.5202 4.9089V6.50015Z"
                    fill="currentColor"
                />
            </svg>
        ),
        name: "Dashboard",
    },

    {
        id: 4,
        path: LEASE_INVEST,
        icon: (
            <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20 12.5C20 11.12 21.12 10 22.5 10V9C22.5 5 21.5 4 17.5 4H7.5C3.5 4 2.5 5 2.5 9V9.5C3.88 9.5 5 10.62 5 12C5 13.38 3.88 14.5 2.5 14.5V15C2.5 19 3.5 20 7.5 20H17.5C21.5 20 22.5 19 22.5 15C21.12 15 20 13.88 20 12.5Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9.5 14.75L15.5 8.75"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M15.4945 14.75H15.5035"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9.49451 9.25H9.50349"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        ),
        name: "Investment",
    },
];

export default tabCommData;
