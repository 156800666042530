/* @DESC:: Tag List @habib610 Tue February 18,2025 */
let community = "Community";
let maintenance = "Maintenance";
let invest = "Investment";
let general = "General";

const generalFaq = [
    {
        question: "What is SROT(स्रोत) platform?",
        answer: `SROT / स्रोत (in Sanskrit) means source or origin, and we want to build SROT(स्रोत) of wealth, energy, and prosperity for all of us.`,
        tags: [general],
    },
    {
        question: "Do solar panels work on cloudy or rainy days?",
        answer: `Yes, solar panels can still generate electricity on cloudy or rainy days. While their efficiency is reduced compared to sunny days, they can capture diffused sunlight and convert it into usable energy.`,
        tags: [general],
    },
    {
        question: "How long do solar panels last?",
        answer: `Solar panels typically have a lifespan of 25-30 years. Most manufacturers offer warranties that guarantee performance for at least 25 years, ensuring long-term reliability.`,
        tags: [general],
    },
    {
        question: "What maintenance is required for solar system?",
        answer: `Solar system require minimal maintenance. Regular cleaning to remove dust and debris, and periodic inspections to ensure all components are functioning properly, are usually sufficient.`,
        tags: [general],
    },
    {
        question: "Will solar panels damage my roof?",
        answer: `When installed correctly, solar panels do not damage your roof. In fact, they can protect the roof from weather-related wear and tear.`,
        tags: [general],
    },
    {
        question:
            "How does solar energy cut down carbon footprint?",
        answer: `Solar panels are capable of generating power with zero carbon emissions. The generated power can replace conventional energy, which presently comes from burning coal or fossil fuels. Every kilowatt of green energy produced by Solar can help you offset 666 KG of CO2 emission annually.`,
        tags: [general],
    },
];
const investmentFaq = [
    {
        question: "Is my investment safe?",
        answer: "Your investment is backed by physical solar assets. With your investment, we set up a solar plant and lease it out to consumers looking to cut down their electricity bill. These consumers are paying only a fraction of their prior bill, if they don’t pay their bill on time, we can move the solar plant to another consumer without impacting your payment.",
        tags: [invest, community],
    },
    {
        question: "How are my investment returns calculated?",
        answer: `You can check the investment returns while showing interest and making investment. Investment returns are given in X% IRR (Internal Rate of Return). To better understand your return, assume you have lent money, say 1 lakh, at 20% rate of interest for 5 years (investment tenure). The borrower will pay you 2649 monthly, which includes both your principal and interest amount.`,
        tags: [invest],
    },
    {
        question: "What is IRR (Internal Rate of Return) and why is it important?",
        answer: `IRR is a metric used to evaluate the profitability of an investment. It represents the annualized rate of return that makes the net present value (NPV) of all cash flows from the investment equal to zero. A higher IRR indicates a more profitable investment.`,
        tags: [invest],
    },
    {
        question: "Why should I invest in solar projects?",
        answer: `Investing in solar projects offers financial returns and environmental benefits. You can earn up to 20% IRR returns while contributing to a sustainable future by reducing reliance on fossil fuels and reducing carbon emissions.`,
        tags: [invest],
    },
    {
        question:
            "What are the benefits of fixed-income investments in my portfolio?",
        answer: `Fixed-income investments provide regular income, capital preservation, and diversification. They help stabilize your portfolio and reduce risk, especially during market volatility.`,
        tags: [invest],
    },
    {
        question: "How can I build a diversified solar portfolio?",
        answer: `By regularly investing in multiple solar projects, you can diversify your portfolio and minimize risk. Diversification helps spread risk across different projects and ensures more stable returns.`,
        tags: [invest],
    },
    {
        question: "Who has ownership of the solar asset?",
        answer: `You will have the fractional ownership of the solar assets proportional to the amount you invested and the total value of the asset.`,
        tags: [invest, community],
    },
    {
        question: "Which agreement will be signed?",
        answer: `All investor required to mandatory sign LLP agreement.`,
        tags: [invest],
    },
    {
        question: "What is the LLP agreement?",
        answer: `For each project we create a separate entity called SPV (Special Purpose Vehicle) to manage all the investments raised. In this SPV, all the investors who are interested in investing will become partners and have ownership in the income generated from the SPV. To become partner in SPV, each investor required to manadatory sign LLP agreement on our platform`,
        tags: [invest],
    },
    {
        question: "What are the KYC requirements for investment?",
        answer: `You need to have a valid PAN, Aadhaar card, and bank account. You need to complete the KYC process on the SROT platform before making any investment.`,
        tags: [invest, general],
    },
    {
        question: "What are the documents required to invest?",
        answer: `The documents required are as follows`,
        list: [
            "PAN Card",
            "Aadhaar Card",
            "Bank Passbook or Cancel cheque mentioning the Account number and IFSC",
        ],
        tags: [invest, general],
    },
    {
        question: "When will I start getting a payout for the investment?",
        answer: "Once a solar plant is commissioned and installed, the opportunity will move to an Active state and your investment payout schedule will be generated. Your plant will become active within 45-60 days from the installation date.",
        tags: [invest, general, community],
    },
];
const communityFaq = [
    {
        question: "What is the role of the society admin/chairman in the investment process?",
        answer: `The society admin/chairman signs up, prepares the investment plan, selects the type of solar panels, and decides the expected rate of return for all community members. They also manage the investment process and communicate with community members.`,
        tags: [community],
    },
    {
        question: "What types of solar panels are available for installation?",
        answer: `There are two types of solar panels: DCR (Domestic Content Requirement) panels and non-DCR panels. DCR panels are required for government subsidies but are more expensive than non-DCR panels.`,
        tags: [community],
    },
    {
        question: "How is the cost of the solar project calculated?",
        answer: `The cost is based on the type of panels selected and the overall project requirements. DCR panels cost more, but they qualify for government subsidies, which can offset the initial investment.`,
        tags: [community],
    },
    {
        question: "How do I show interest in investing in the solar rooftop project?",
        answer: `Admin can create the investment plan, the sharable link is generated for society members to sign up and register on the platform. Members can express their interest and tell us how much they are willing to invest.`,
        tags: [community],
    },
    {
        question: "How does the community benefit from investing in solar projects?",
        answer: `Once the solar project is live, the residential society will save on their electricity bill. The saving will depend on the investment plan prepared by the admin, the higher the returns, the lower the saving for the society. Normally society will save nearly 40% by subscribing to the lease investment model. And after 5 years, society will get free electricity for the next 20 years. This is a win-win proposition for all.`,
        tags: [community],
    },
];
const performanceFaq = [
    {
        question: "How much electricity can a solar panel generate?",
        answer: `The amount of electricity generated depends on the panel's efficiency, size, and the amount of sunlight it receives. On average, a 1 kW solar panel system can generate about 4-5 kWh of electricity per day.`,
        tags: [maintenance. general],
    },
    {
        question: "What happens if the solar panels produce more electricity than needed?",
        answer: `The solar system is grid connected with net metering. The excess electricity can be fed back into the grid during the day, and can be utilised at night time when there is higher demand. All excess electricity units generated from the panel will be banked in the grid for one year.`,
        tags: [maintenance],
    },
    {
        question: "How does net metering work?",
        answer: `Net metering is a billing mechanism that credits solar energy system owners for the electricity they add to the grid. If your system produces more electricity than you use, the excess is sent to the grid, and you receive credits on your electricity bill.`,
        tags: [maintenance],
    },
    {
        question: "What is the difference between DCR and non-DCR panels?",
        answer: `DCR panels are manufactured in compliance with domestic content requirements and qualify for government subsidies. Non-DCR panels do not meet these requirements but are generally less expensive.`,
        tags: [maintenance],
    },
    {
        question: "How does solar power contribute to environmental sustainability?",
        answer: `Solar power reduces reliance on fossil fuels, decreases greenhouse gas emissions, and lowers air pollution. It is a clean, renewable energy source that helps combat climate change`,
        tags: [maintenance],
    },
];
const maintenanceFaq = [
    {
        question: "What is the installation process for solar panels?",
        answer: `The installation process involves site assessment, system design, obtaining permits, installing the panels and inverters, and connecting the system to the grid. It typically takes 60-90 days to complete.`,
        tags: [maintenance, general],
    },
    {
        question: "Who will manage the installation plant and maintenance of the solar panels?",
        answer: `Our Hypersrot Team will handle the entire installation process, including net metering, government approvals, load extensions, and subsidy applications. We also take care of maintenance, cleaning, insurance, and any issues that arise during the lease tenure.`,
        tags: [maintenance, general],
    },
    {
        question: "What is the lease plan duration for the solar project?",
        answer: `The lease plan duration is 5 years. During this time, we manage all aspects of the project, including maintenance and repairs.`,
        tags: [maintenance],
    },
    {
        question: "What happens after the 5-year lease period?",
        answer: `After the 5-year lease period, the project is handed over to the society. The society can then enjoy free electricity for the next 20 years, resulting in significant long-term savings.`,
        tags: [maintenance],
    },
    {
        question: "How do I apply for government subsidies for the solar project?",
        answer: `To apply for government subsidies, you need to install DCR panels. We handle the subsidy application process on your behalf, ensuring you receive the maximum benefits available.`,
        tags: [maintenance],
    },
];

/* @DESC:: Merging above faqs into one list of array @habib610 Tue February 18,2025 */
export let leaseFaqs = [
    ...generalFaq,
    ...investmentFaq,
    ...communityFaq,
    ...performanceFaq,
    ...maintenanceFaq,

];

/* @DESC:: Tag which is being used in filtering buttons on top @habib610 Tue February 18,2025 */
export const leaseFaqTags = [
    {
        id: 1,
        name: general,
    },
    {
        id: 2,
        name: invest,
    },
    {
        id: 3,
        name: community,
    },
    {
        id: 4,
        name: maintenance,
    },
];
