import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { Redirect, Switch, useLocation } from "react-router-dom";
import AboutUs from "./app/about-us";
import BlogDetails from "./app/blog-details";
import ContactUS from "./app/contact-us";
import CutomerSupport from "./app/customerSupport/index.js";
import Home from "./app/home";
import ExistingUserDashBoard from "./app/home/ExistingUser";
import NewUser from "./app/home/ExistingUser/NewUser";
import Impact from "./app/impact/index.js";
import KYC from "./app/kyc/index.js";
import PortfolioDash from "./app/portfoilo/desktop/index.js";
import PortfolioDetails from "./app/portfoilo/mobile/portfolio-details/index.js";
import Profile from "./app/profile";
import Projects from "./app/project/index.js";
import InvestProcess from "./app/project/mobile/invest/InvestProcess.js";
import ProjectDetails from "./app/project/ProjectDetails/index.js";
import RewardsDashBoard from "./app/rewards/index.js";
import Referral from "./app/rewards/mobile/Referral.js";
import SignUp from "./app/signup/index.js";
import TermsAndConditions from "./app/terms-and-condition";
import ErrorFallback from "./components/error/ErrorFallback";
import LoadingSpinner from "./components/loading/LoadingSpinner";

import {
    ABOUTUS,
    BLOG,
    CONTACTUS,
    CUSTOMERSUPPORT,
    ERROR_SCREEN,
    FAQ,
    FORGET,
    HOME,
    IMPACT,
    INVEST,
    LEASE_DASHBOARD,
    LEASE_HOME,
    LEASE_INVEST,
    LEASE_LOGIN,
    LEASE_PLAN,
    LEASE_REGISTER,
    LOGIN,
    NOT_FOUND,
    PORTFOLIO,
    PROFILE,
    PROJECTS,
    REFERRAL,
    RESET_SCREEN,
    REWARDS,
    SIGNUP,
    TERMS_N_CONDITIONS,
    UNAUTHORIZED,
    USER_HOME,
    VERIFY_EMAIL,
} from "./constants/routes";

import LeaseDashboard from "./app/lease/dashboard/index.js";
import LeaseHome from "./app/lease/home/index.js";
import LeaseInterest from "./app/lease/interest/index.js";

import { useHistory } from "react-router-dom";
import Login from "./app/login/index.js";
import ResetPassword from "./app/login/ResetPassword.js";
import LoginAdmin from "./app/loginAdmin/index.js";
import NotFoundPage from "./app/loginAdmin/NotFoundPage.js";
import SignUpAdmin from "./app/signupAdmin/index.js";
import SummaryAdmin from "./app/summaryAdmin/index.js";
import Unauthorized from "./app/unauthorised/Unauthorized.js";
import { ROLE } from "./constants/common.js";
import { useContextState } from "./context/ContextProvider";
import FrequentQuestion from "./faq/FrequentQuestion";
import { checkAuthorization } from "./helpers/routingHelper.js";
import useAuth from "./hooks/useAuth";
import ResetScreen from "./reset/ResetScreen";
import PrivateRoute from "./routes/PrivateRoute.js";
import PublicRoute from "./routes/PublicRoute.js";
import auth from "./services/auth";
import VerifyEmail from "./verify-email";

const routeConfigs = [
    {
        path: HOME,
        RenderComponent: Home,
        isAuthReq: false,
        exact: true,
    },
    {
        path: USER_HOME,
        RenderComponent: ExistingUserDashBoard,
        isAuthReq: true,
        exact: true,
    },
    {
        path: LEASE_PLAN,
        RenderComponent: SummaryAdmin,
        isAuthReq: true,
        exact: false,
        allowed: [ROLE.CONSUMER_ADMIN, ROLE.SUPER_ADMIN, ROLE.SALES_MANAGER],
    },
    {
        path: ABOUTUS,
        RenderComponent: AboutUs,
        isAuthReq: false,
        exact: true,
    },
    {
        path: `${BLOG}/:blogId`,
        RenderComponent: BlogDetails,
        isGlobal: true,
        isAuthReq: false,
    },
    {
        path: FAQ,
        RenderComponent: FrequentQuestion,
        isAuthReq: false,
    },
    {
        path: TERMS_N_CONDITIONS,
        RenderComponent: TermsAndConditions,
        isAuthReq: false,
    },
    {
        path: LOGIN,
        RenderComponent: Login,
        isAuthReq: false,
        exact: true,
    },
    {
        path: LEASE_LOGIN,
        RenderComponent: LoginAdmin,
        isAuthReq: false,
        exact: true,
    },
    {
        path: `${SIGNUP}`,
        RenderComponent: SignUp,
        isAuthReq: false,
        exact: true,
    },
    {
        path: LEASE_REGISTER,
        RenderComponent: SignUpAdmin,
        isAuthReq: false,
        exact: false,
    },
    {
        path: `${FORGET}`,
        RenderComponent: ResetPassword,
        isAuthReq: false,
        exact: true,
    },

    {
        path: IMPACT,
        RenderComponent: Impact,
        isAuthReq: false,
        exact: true,
    },
    {
        path: CONTACTUS,
        RenderComponent: ContactUS,
        isAuthReq: false,
    },
    {
        path: PROJECTS,
        RenderComponent: Projects,
        isAuthReq: true, // change
        // isGlobal: true,
        exact: true,
    },
    {
        path: `${PROJECTS}/:id`,
        RenderComponent: ProjectDetails,
        isAuthReq: true, //change
        exact: false,
    },
    {
        path: CUSTOMERSUPPORT,
        RenderComponent: CutomerSupport,
        isAuthReq: true, // change
        exact: true,
        isGlobal: true,
    },
    {
        path: `${INVEST}/:listingId`,
        RenderComponent: InvestProcess,
        isAuthReq: true, //change
        exact: true,
    },
    {
        path: REWARDS,
        RenderComponent: RewardsDashBoard,
        isAuthReq: true, //change
        exact: true,
    },
    {
        path: `${REWARDS}${REFERRAL}`,
        RenderComponent: Referral,
        isAuthReq: false, //change
        exact: true,
    },
    {
        path: "/kyc/summary",
        RenderComponent: KYC,
        isAuthReq: true, //change
        isGlobal: true,
        exact: false,
    },

    {
        path: PORTFOLIO,
        RenderComponent: PortfolioDash,
        isAuthReq: true, // change
        exact: true,
    },
    {
        path: `${PORTFOLIO}/:id`,
        RenderComponent: PortfolioDetails,
        isAuthReq: true, // change
        exact: false,
    },
    {
        path: "/newUser",
        RenderComponent: NewUser,
        isAuthReq: false, // change
        exact: true,
    },

    {
        path: PROFILE,
        RenderComponent: Profile,
        isAuthReq: true,
        exact: true,
    },
    {
        path: VERIFY_EMAIL,
        RenderComponent: VerifyEmail,
        isAuthReq: false,
    },

    {
        path: LEASE_HOME,
        RenderComponent: LeaseHome,
        isAuthReq: true,
    },
    {
        path: LEASE_DASHBOARD,
        RenderComponent: LeaseDashboard,
        isAuthReq: true,
    },
    {
        path: LEASE_INVEST,
        RenderComponent: LeaseInterest,
        isAuthReq: true,
    },
    {
        path: RESET_SCREEN, // TODO  : check if we need to redirect ?
        RenderComponent: ResetScreen,
        isAuthReq: false,
    },

    {
        path: ERROR_SCREEN,
        RenderComponent: ErrorFallback,
        isAuthReq: false,
    },
    {
        path: NOT_FOUND,
        RenderComponent: NotFoundPage,
        isAuthReq: false,
    },
    {
        path: UNAUTHORIZED,
        RenderComponent: Unauthorized,
        isAuthReq: false,
    },
];

const RouteComponent = ({
    RenderComponent,
    isAuthReq,
    redirectIfAuth,
    isGlobal,
    ...restProps
}) => {
    const { state, dispatch } = useContextState();
    const { isAuth } = useAuth(restProps?.location?.pathname, dispatch);
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        if (!state.user && isAuth) {
            auth.getUserProfile(dispatch);
        }
    }, [dispatch, isAuth, state.user]);

    useEffect(() => {
        if (isAuth !== null && !showContent) {
            setTimeout(() => {
                setShowContent(true);
            }, 5000);
        }
    }, [showContent, isAuth]);

    if (isAuth === null) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <AnimatePresence>
            {isAuthReq ? (
                <PrivateRoute component={RenderComponent} {...restProps} />
            ) : isGlobal ? (
                <RenderComponent {...restProps} />
            ) : (
                <PublicRoute component={RenderComponent} {...restProps} />
            )}
        </AnimatePresence>
    );
};

export const ScrollToTop = () => {
    const { state, dispatch } = useContextState();
    const { pathname, search } = useLocation();
    const history = useHistory();
    const getQueryParams = (queryString) => {
        return new URLSearchParams(queryString);
    };

    const queryParams = getQueryParams(search);
    const code = queryParams.get("code");
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (state.user && location.pathname.includes("lease")) {
            checkAuthorization(code, dispatch, history);
        }
    }, [code, dispatch, history, location.pathname, pathname, state.user]);

    return null;
};

const Routes = () => {
    return (
        <>
            <ScrollToTop />
            <Switch>
                {routeConfigs.map((routeConfig) => (
                    <RouteComponent {...routeConfig} />
                ))}
                <Redirect to={NOT_FOUND} />
            </Switch>
        </>
    );
};

export default Routes;
