import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import React, { useEffect, useMemo, useState } from "react";
import cn from "../../lib/cn";
import Section from "../../shared/Section";

const Hero = ({
    content,
    url,
    align,
    type,
    wrapperClass,
    containerClass,
    contentClass,
    videoClass,
}) => {
    const [init, setInit] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {};

    const options = useMemo(
        () => ({
            fullScreen: {
                enable: false,
            },
            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: false,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                },

                modes: {
                    push: {
                        quantity: 2,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: "#ffffff",
                },
                links: {
                    color: "#ffffff",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                        default: "bounce",
                    },
                    random: false,
                    speed: 2,
                    straight: false,
                    bounce: true,
                },
                number: {
                    density: {
                        enable: true,
                    },
                    value: 120,
                },
                opacity: {
                    value: 0.6,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    value: { min: 1, max: 5 },
                },
            },
            detectRetina: false,
        }),
        []
    );

    return (
        <Section className={containerClass}>
            <div
                className={cn(
                    "relative max-h-[593px]   md:max-h-[993px] lg:max-h-[603px]  xl:h-screen",
                    wrapperClass
                )}
            >
                {init && (
                    <Particles
                        id="tsparticles"
                        options={options}
                        particlesLoaded={particlesLoaded}
                        className=" h-[593px] md:h-[993px] lg:h-[603px] xl:h-screen "
                    />
                )}

                <div
                    className={cn(
                        `absolute inset-x-0 inset-y-0  h-[593px]  md:h-[993px] lg:h-[603px] xl:h-screen flex isolate z-40`,
                        contentClass
                    )}
                >
                    {content}
                </div>
            </div>
            {type === "video" ? (
                <video
                    src={url}
                    className={cn(
                        "absolute inset-y-0 inset-x-0  h-[593px]  md:h-[993px] lg:h-[603px] xl:h-screen w-full object-cover",
                        videoClass
                    )}
                    autoPlay
                    loop
                    muted
                    style={{ zIndex: 2 }}
                />
            ) : (
                <img
                    style={{ zIndex: 2 }}
                    src={url}
                    className="absolute inset-y-0 inset-x-0 h-[593px]  md:h-[993px] lg:h-[603px] xl:h-screen w-full object-cover"
                    alt="hero"
                />
            )}
        </Section>
    );
};

export default Hero;
