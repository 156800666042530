import React from "react";
import ModalAlt from "../../components/modal/ModalAlt";
import Button from "./Button";
import OTP from "./OTP";

const OTPModal = ({
    isOpen,
    onClose,
    formData,
    errors,
    handleResentOTP,
    handleChange,
    handleSubmit,
}) => {
    return (
        <ModalAlt isOpen={isOpen} onClose={onClose} times={false}>
            <div className="p-2">
                <OTP
                    otp={formData.otp}
                    onChange={handleChange}
                    error={!!errors?.otp}
                    errorMessage={errors.otp}
                    handleResentOTP={handleResentOTP}
                />
                <Button
                    className="bg-black text-white mt-1 !rounded-[3rem] w-full"
                    onClick={handleSubmit}
                >
                    Verify
                </Button>
            </div>
        </ModalAlt>
    );
};

export default OTPModal;
