import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { copy, send } from "../../resources/js/icons";

const Share = ({ code, children }) => {
    const history = useHistory();
    const textToCopy = `Join Our Solar Community.  

You can earn up to 20% returns, and your society will benefit from significant savings on electricity bills. It's a win-win for everyone!  
Together, our small contributions can make a big difference.  

http://srot.app/lease/register?code=${code} 
`;

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            toast.success("Text copied to clipboard!");
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    };
    return (
        <div className="pt-5">
            <div className="flex items-center justify-center">
                <img src={send} alt="sent" className="w-8 h-8" />
            </div>
            <p className="font-vietnam text-sm text-pretty text-gray-secondary my-3">
                Invite your friends and family to invest in the solar project.
                Together, our small contributions can make a big difference.
            </p>

            <div className="flex w-full  justify-between  items-center  gap-1">
                <div className="text-base w-[80%] bg-gray-50 border border-gray-400  rounded-sm">
                    <div className=" px-0.5 line-clamp-1 text-center text-xs xs:text-sm md:text-sm xl:text-sm font-normal font-vietnam  py-0.8 text-blue ">
                        {`https://srot.app/leasing/register?code=${code}`}
                    </div>
                </div>

                <div
                    className=" rounded-sm   border p-0.5 border-gray-550 flex items-center justify-center"
                    role="button"
                    onClick={copyToClipboard}
                >
                    <img src={copy} alt="copy" className="h-3 w-3" />
                </div>
            </div>

            {children}
        </div>
    );
};

export default Share;
