// @flow
import React, { useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import { useHistory } from "react-router-dom";
import { investNow } from "../../resources/js/icons";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H3 from "../../shared/typography/H3";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import PillSlider from "../../shared/ui/Slider/PillSlider";
import VerticalPillSlider from "../../shared/ui/Slider/VerticalPillSlider";

const Card = ({ image, text, heading, link, btnText }) => {
    const history = useHistory();
    return (
        <section className="panel  bg-white flex justify-center lg:py-1 xl:py-2 2xl:py-3">
            <div className="lg:flex justify-between gap-4 items-center  2xl:w-10/12">
                <div className="lg:w-5/12  2xl:w-4/12">
                    <img
                        src={image}
                        alt="mobileApp-img"
                        // className=" h-[200px] sm:h-[54rem]  lg:h-[56rem] w-auto"
                        className="!w-[250px] sm:!w-[300px] md:!w-[400px] lg:!w-auto lg:h-[500px] 2xl:h-[560px]"
                    />
                </div>
                <div className=" text-start  lg:w-7/12   xl:w-6/12 mt-2">
                    <div className="hidden lg:flex items-start w-10 mb-0">
                        <img
                            src={investNow}
                            alt="mobileApp-img"
                            className="h-3 md:h-7 "
                        />
                    </div>
                    <H3
                        className={
                            " mt-1 lg:mt-0 mb-0.5 lg:mb-2 font-medium text-xl md:text-2xl"
                        }
                    >
                        {heading}
                    </H3>
                    <P className={"mb-1 md:mb-2 text-base"}>{text}</P>
                    <Button
                        onClick={() => history.push(link)}
                        className=" xl:text-sm 2xl:text-xxl"
                    >
                        {btnText}
                    </Button>
                </div>
            </div>
        </section>
    );
};

const InvestNow = ({ cardData }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };

    return (
        <Section>
            <Container className="flex flex-col-reverse lg:flex-row gap-1 pt-3">
                <div className=" hidden lg:block">
                    <InvestCarousel
                        cardData={cardData}
                        currentSlide={currentSlide}
                        setCurrentSlide={setCurrentSlide}
                        axis={"vertical"}
                    />
                </div>

                <div className="block lg:hidden">
                    <InvestCarousel
                        cardData={cardData}
                        currentSlide={currentSlide}
                        setCurrentSlide={setCurrentSlide}
                        axis={"horizontal"}
                    />
                </div>

                <div className="hidden lg:flex">
                    <VerticalPillSlider
                        currentSlide={currentSlide}
                        className={"mx-3 justify-center items-center"}
                        data={cardData}
                        handleSlideChange={handleSlideChange}
                    />
                </div>
                <div className="lg:hidden ">
                    <PillSlider
                        currentSlide={currentSlide}
                        className={"mx-3 justify-center items-center"}
                        width="w-9"
                        data={cardData}
                        handleSlideChange={handleSlideChange}
                    />
                </div>
            </Container>
        </Section>
    );
};

export default InvestNow;

const InvestCarousel = ({ cardData, currentSlide, setCurrentSlide, axis }) => {
    return (
        <CarouselComponent
            selectedItem={currentSlide}
            onChange={setCurrentSlide}
            axis={axis}
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            autoPlay={true}
            infiniteLoop={true}
            interval={3000}
            stopOnHover={true}
            transitionTime={500}
            swipeable={true}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            emulateTouch={true}
        >
            {cardData.map((card, index) => (
                <Card
                    heading={card.heading}
                    text={card.text}
                    image={card.image}
                    key={card.id}
                    link={card.link}
                    btnText={card.btnText}
                />
            ))}
        </CarouselComponent>
    );
};
