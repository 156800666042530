// Stepper.js
import React from "react";

const Stepper = ({ steps, currentStep, onClick }) => {
    return (
        <div className="flex items-center justify-between  w-full ">
            {steps.map((step, index) => (
                <div
                    key={step}
                    className={`flex items-center justify-center ${
                        index < steps.length - 1 && "w-full"
                    }`}
                >
                    <div
                        className="flex-col items-center justify-center"
                        onClick={() => onClick(index)}
                    >
                        <div
                            className={`w-2 h-2 mb-1 rounded-full ml-1  flex items-center justify-center border   
              ${
                  index <= currentStep
                      ? "bg-green-500 border-green-500"
                      : "bg-gray-400"
              }   
              text-white`}
                        ></div>

                        <span className={` mt-3 text-xs text-gray-secondary `}>
                            {step}
                        </span>
                    </div>
                    {index < steps.length - 1 && (
                        <div
                            className={`flex-1 h-0.1 w-[90%]   mb-2 
                ${
                    index < currentStep ? "bg-green-500" : "bg-gray-400"
                } transition-all duration-300`}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default Stepper;
