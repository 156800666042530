import React from "react";
import { useHistory } from "react-router-dom";
import { LEASE_INVEST } from "../../../constants/routes";
import Container from "../../../shared/Container";
import P from "../../../shared/typography/P";

const InterestBanner = () => {
    const history = useHistory();
    return (
        <div className="bg-linear-silver-blue  lg:my-2 lg:rounded-2 lg:shadow-sm">
            <Container>
                <div className="py-1">
                    <P className="text-gray  text-sm 2xs:text-base  ">
                        <span className="mr-0.5">
                            You have not shown interest in the lease plan, If
                            you want to invest
                        </span>
                        <span
                            role="button"
                            onClick={() => history.push(LEASE_INVEST)}
                            className="text-blue hover:cursor-pointer "
                        >
                            Click Here
                        </span>
                    </P>
                </div>
            </Container>
        </div>
    );
};

export default InterestBanner;
