import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ROLE } from "../../constants/common";
import { LEASE_PLAN } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { checkIsAllowed } from "../../helpers/helpers";
import useAuth from "../../hooks/useAuth";
import cn from "../../lib/cn";
import Container from "../Container";
import P from "../typography/P";
import tabCommData from "./tabCommunityData";
import tabData from "./tabData";

const handleActiveTab = (location, path) => {
    if (location.slice(1).toLowerCase().includes(path.slice(1).toLowerCase())) {
        return true;
    } else return false;
};

export const getFilteredTabData = (roles) => {
    let filteredTabs = [...tabCommData];

    if (
        !checkIsAllowed(roles, [
            ROLE.CONSUMER_ADMIN,
            ROLE.SUPER_ADMIN,
            ROLE.SALES_MANAGER,
        ])
    ) {
        filteredTabs = filteredTabs.filter(
            (tab) => !tab.path.includes(LEASE_PLAN)
        );
    }

    return filteredTabs;
};

const TabNavigator = () => {
    const { pathname, search } = useLocation();

    const history = useHistory();
    const { isAuth } = useAuth(pathname);
    const { state } = useContextState();

    const getQueryParams = (queryString) => {
        return new URLSearchParams(queryString);
    };

    const queryParams = getQueryParams(search);
    const code = queryParams.get("code");
    if (!(isAuth && state?.user)) return null;

    const data =
        code || pathname.includes("lease")
            ? getFilteredTabData(state?.user?.roles)
            : tabData;

    return (
        <div className=" lg:hidden fixed inset-x-0 bottom-0 bg-white py-1.6  border-t border-gray-400  flex items-center z-100 ">
            <Container>
                <div className="flex justify-between ">
                    {data.map((item) => (
                        <div
                            key={item.id}
                            className="flex flex-col items-center hover:cursor-pointer"
                            role="button"
                            onClick={() => {
                                code
                                    ? history.push(`${item.path}?code=${code}`)
                                    : history.push(item.path);
                            }}
                        >
                            <div
                                className={cn("h-[24px]  ", {
                                    "text-primary": handleActiveTab(
                                        pathname,
                                        item.name
                                    ),
                                })}
                            >
                                {item.icon}
                            </div>

                            <P
                                className={cn({
                                    "text-primary": handleActiveTab(
                                        pathname,
                                        item.name
                                    ),
                                })}
                            >
                                {item.name}
                            </P>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default TabNavigator;
