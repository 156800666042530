import React, { useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import { useHistory, useRouteMatch } from "react-router-dom";
import { hi } from "../../resources/js/icons";
import { login1, login2, login3 } from "../../resources/js/images";
import Container from "../../shared/Container";
import H3 from "../../shared/typography/H3";
import PillSlider from "../../shared/ui/Slider/PillSlider";
import MembersDetails from "./MembersDetails";
import WelcomeScreen2 from "./WelcomeScreen2";
import WelcomeScreen3 from "./WelcomeScreen3";
import WelcomeScreen4 from "./WelcomeScreen4";
const imgData = [
    {
        id: 1,
        img: login1,
    },
    {
        id: 1,
        img: login2,
    },
    {
        id: 1,
        img: login3,
    },
    {
        id: 1,
        img: login2,
    },
];
const AuthWrapper = ({
    heading = "Welcome!",
    planDetails,
    text = "Please Sign in to your Srot account",
    children,
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const { path, url } = useRouteMatch();
    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };

    const [signUp, setSignUp] = useState(false);
    const [login, setLogin] = useState(false);

    const history = useHistory();
    const handleLogIn = () => {
        setSignUp(false);
        setLogin((prev) => !prev);
    };
    const handleSignUp = () => {
        setLogin(false);
        setSignUp((prev) => !prev);
    };

    const handleOnboarding = () => {
        setLogin(false);
        setSignUp(false);
    };
    return (
        <div className="relative max-h-screen md:max-h-max lg:py-4">
            <div className=" hidden md:block absolute top-0 left-0 w-1/2 bg-white  bottom-0 -z-10"></div>
            <div className=" hidden md:block absolute top-0 right-0 w-1/2 bg-primary bottom-0 -z-10"></div>

            <div className="bg-black-600 md:bg-transparent">
                <Container className="flex mx-0 !max-w-full  px-0 md:container  justify-between md:items-center  h-full md:gap-2 lg:gap-5">
                    <div className="hidden w-1/2  gap-3 md:flex flex-col justify-center items-center ">
                        <div className=" w-full xl:w-3/3 mx-3 ">
                            <div className="mb-2 pr-2 flex-col items-center text-center">
                                <div className="flex items-center justify-center">
                                    <img
                                        src={hi}
                                        alt="hi"
                                        className="w-8 h-8"
                                    />
                                </div>
                                <H3 className="font-medium">
                                    {planDetails?.plantMeta?.plantName}
                                </H3>
                            </div>
                            {/* <P className="text-white ">
                                Lorem ipsum dolor sit amet consectetur. Neque
                                felis consectetur aenean mauris. Arcu senectus
                                gravida ac fermentum ut.
                            </P> */}
                        </div>
                        <div className="w-full md:w-[55%] 2xl:w-1/2">
                            <CarouselComponent
                                selectedItem={currentSlide}
                                onChange={setCurrentSlide}
                                showArrows={false}
                                showThumbs={false}
                                showStatus={false}
                                showIndicators={false}
                                autoPlay={true}
                                infiniteLoop={true}
                                interval={3000}
                                stopOnHover={true}
                                transitionTime={500}
                                swipeable={true}
                                preventMovementUntilSwipeScrollTolerance={true}
                                swipeScrollTolerance={50}
                                emulateTouch={true}
                                centerMode={true}
                                centerSlidePercentage={100}
                            >
                                <WelcomeScreen2 />
                                <WelcomeScreen3 />
                                <WelcomeScreen4 />
                            </CarouselComponent>
                            <PillSlider
                                className={"mt-0"}
                                currentSlide={currentSlide}
                                data={[1, 2, 3]}
                                handleSlideChange={handleSlideChange}
                            />
                        </div>
                        <MembersDetails planDetails={planDetails} />
                    </div>
                    <div className="flex-1 min-h-screen md:items-center md:justify-center md:min-h-screen px-0 w-full pt-[3.5rem] md:pt-3 lg:pt-3 bg-black-600 md:bg-transparent  xl:pl-5 2xl:pl-8 ">
                        {children}
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default AuthWrapper;
