import React, { useState } from "react";
import P from "../../../../shared/typography/P";
import CalculatorRangeSlider from "../../../project/mobile/project-details/CalculatorRangeSlider";
import { getCurrencyAmount } from "../../../../helpers/helpers";

let calculateEMI = (loanAmount, annualInterestRate, loanTenure = 5) => {
    let monthlyInterestRate = annualInterestRate / 100 / 12;
    let numberOfPayments = loanTenure * 12;

    return (
        (loanAmount *
            monthlyInterestRate *
            Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
        (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1)
    );
};
const InvestmentCalculator = ({
    min,
    max,
    step = 50000,
    investmentReturn = 20,
    monthlyPayoutPerLakh,
    initialAmount = 50000,
}) => {
    const [amount, setAmount] = useState(initialAmount);

    const monthlyPayout = calculateEMI(amount, investmentReturn);
    const glance = [
        {
            name: "Investment Tenure",
            value: "5 years",
        },
        {
            name: "Investment Return",
            value: ` ${investmentReturn}%`,
        },
        {
            name: "Monthly Payouts",
            value:
                "₹ " +
                Number(Number(monthlyPayout).toFixed(1)).toLocaleString(),
        },
        {
            name: "Total payouts",
            value:
                "₹ " +
                Number(
                    (Number(monthlyPayout) * 60).toFixed(1)
                ).toLocaleString(),
        },
    ];
    const rangeValue = {
        min: min ? min : 0,
        max: max ? max : 0,
    };
    // Function to calculate the left position of the amount text
    const calculatePosition = (value) => {
        // Get percentage position of the slider value
        const percentage =
            ((value - rangeValue.min) / (rangeValue.max - rangeValue.min)) *
            100;
        return percentage; // returns value from 0 to 100
    };

    const handleSliderChange = (e) => {
        setAmount(e.target.value);
    };

    return (
        <div className="">
            <div className="bg-gray-50  border border-gray-400 rounded-2xl  px-2 md:mx-0 py-1 relative">
                <div className="flex justify-between mb-2">
                    <P className="text-gray-600 text-xs 2xs:text-sm font-vietnam ">
                        Amount you want to invest
                    </P>
                    <P className="font-vietnam text-primary text-xs 2xs:text-sm">
                        {/* ₹{Number(amount).toLocaleString()} */}
                        {getCurrencyAmount(amount)}
                    </P>
                </div>

                <div className="relative">
                    <CalculatorRangeSlider
                        min={rangeValue.min}
                        max={rangeValue.max}
                        value={amount}
                        onChange={handleSliderChange}
                        step={step}
                    />

                    {/* <p
                        className="text-sm text-blue-500 text-center absolute"
                        style={{
                            left: `calc(${calculatePosition(amount)}% - 0px)`, // Adjust as necessary to align with the slider thumb
                            top: "-30px", // Position above the slider
                            transform: "translateX(-50%)", // Center align the text
                            whiteSpace: "nowrap", // Prevent wrapping
                        }}
                    >
                        ₹ {amount.toLocaleString()}
                    </p> */}
                </div>
                <div className="flex justify-between">
                    <P className="text-gray-550 "> {getCurrencyAmount(rangeValue.min)}</P>
                    <P className="text-gray-550">{getCurrencyAmount(rangeValue.max)}</P>
                </div>

                <div className="h-0.1 bg-gray-400 w-full my-1"></div>

                <div className="grid grid-cols-2 gap-y-1 mt-1">
                    {glance.map((item, i) => (
                        <div key={i}>
                            <P className="text-gray-550  text-xs xl:text-sm font-vietnam">
                                {item.name}
                            </P>
                            <P className="text-gray text-sm sm:text-base font-vietnam ">
                                {item.value}
                            </P>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InvestmentCalculator;
