import React, { useCallback, useMemo, useState } from "react";
import { IoChevronBack } from "react-icons/io5";
import { Link, useHistory, useLocation } from "react-router-dom";
// import { logo, logoWhite } from "../../assets";
import {
    FORGET,
    LANDING,
    LEASE_DASHBOARD,
    LEASE_HOME,
    LEASE_INVEST,
    LEASE_LOGIN,
    LEASE_PLAN,
    LOGIN,
    PORTFOLIO,
    PROFILE,
    PROFILE_LABEL,
    PROJECTS,
    REWARDS,
    SIGNUP,
    USER_HOME,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import useAuth from "../../hooks/useAuth";
import useHeaderScroll from "../../hooks/useHeaderScroll";
import useScroll from "../../hooks/useScroll";
import cn from "../../lib/cn";
import { logo, logoWhite } from "../../resources/js/images";
import auth from "../../services/auth";
import Container from "../Container";
import { getFilteredTabData } from "../tab-navigator";
import tabData from "../tab-navigator/tabData";
import P from "../typography/P";
import Button from "../ui/Button";
import Hamburger from "./Hamburger";
import HeaderMenu from "./HeaderMenu";
import HeaderProfileDropDown from "./HeaderProfileDropDown";
import { unAuthenticLinks } from "./links";

const disableHeaderScrollLinks = [];

export const AUTH_DASHBOARD_DOWN_LINKS = [
    {
        name: PROFILE_LABEL,
        path: PROFILE,
    },
];

export const ADMIN_LINKS = [];

const BACK_BUTTONS_PATH = [
    PROJECTS,
    PORTFOLIO,
    REWARDS,
    USER_HOME,
    LEASE_HOME,
    LEASE_DASHBOARD,
    LEASE_PLAN,
    LEASE_INVEST,
];

// const baseStyles = "fixed z-50 transition-all ease-in-out duration-500 left-0 right-0";

const baseStyles =
    "fixed z-50   left-0 right-0 transition-all ease-in-out duration-500";
/* @TODO => Fix label @habib610 Tue November 19,2024 */

const Header = ({ handleModal }) => {
    const { state, dispatch } = useContextState();
    const { scrollY } = useScroll();
    const history = useHistory();
    const [showBurger, setShowBurger] = useState(false);
    const { visible } = useHeaderScroll();
    const { pathname, search } = useLocation();
    const getQueryParams = (queryString) => {
        return new URLSearchParams(queryString);
    };

    const queryParams = getQueryParams(search);
    const code = queryParams.get("code");
    const data =
        code || pathname.includes("lease")
            ? getFilteredTabData(state?.user?.roles)
            : tabData;
    const scrollStyles = visible ? "top-0" : "top-[-100px]";

    const { isAuth } = useAuth(pathname);

    let getDisabledLinks = useCallback((pathname) => {
        for (let i = 0; i < disableHeaderScrollLinks.length; i++) {
            if (pathname.includes(disableHeaderScrollLinks[i])) return true;
        }
        return false;
    }, []);

    const isVisible = useMemo(
        () => scrollY > 100 || getDisabledLinks(pathname),
        [getDisabledLinks, pathname, scrollY]
    );

    const handleLogout = () => {
        auth.logout(dispatch)
            .then(() => {
                let redirectPath = pathname.includes("lease")
                    ? `${LEASE_LOGIN}?code=${code}`
                    : LOGIN;

                history.push(redirectPath);
            })
            .catch(() => {});
    };

    let loggedIn = isAuth && state?.user;

    let brand = (
        <Link
            to={pathname.includes("lease") && loggedIn ? LEASE_HOME : LANDING}
            className="duration-500 transition-all"
        >
            <span className="sr-only">Hypersrot </span>
            {loggedIn ? (
                <>
                    <img
                        src={logo}
                        className={cn(
                            `h-[30px] sm:h-[37px] w-auto   transition-all ease-in-out duration-500 hidden md:inline-flex`,
                            {
                                "md:hidden":
                                    !isVisible && pathname.includes(LEASE_HOME),
                            }
                        )}
                        alt="HyperSrot_logo"
                    />
                    <img
                        src={logoWhite}
                        className={cn(
                            `h-[30px] sm:h-[37px] w-auto   transition-all ease-in-out duration-500 md:hidden`,
                            {
                                "md:inline-flex":
                                    !isVisible && pathname.includes(LEASE_HOME),
                            }
                        )}
                        alt="HyperSrot_logo"
                    />
                </>
            ) : (
                <img
                    src={isVisible ? logo : logoWhite}
                    className={`h-[30px] sm:h-[37px] w-auto 
ease-in-out duration-500 transition-all`}
                    alt="HyperSrot_logo"
                />
            )}
        </Link>
    );

    const showBack =
        loggedIn && !BACK_BUTTONS_PATH.includes(pathname) && history.length > 1;

    return (
        <div>
            <header
                className={cn(baseStyles, scrollStyles, {
                    hidden:
                        pathname === LOGIN ||
                        pathname === SIGNUP ||
                        pathname === FORGET,
                    // "bg-gray-60": isVisible && !loggedIn,

                    "bg-gray-60": !loggedIn && isVisible,
                    "md:hidden":
                        pathname === `/lease${LOGIN}` ||
                        pathname === `/lease/register`,
                    "bg-black-600 md:bg-gray-70 w-full":
                        loggedIn &&
                        (isVisible || !pathname.includes(LEASE_HOME)),
                })}
                style={{
                    zIndex: 101,
                }}
            >
                <Container>
                    <nav
                        className={`flex items-center  px-2 lg:bg-transparent justify-between py-1 lg:p-2.4
                        rounded-1.5 `}
                        aria-label="Global"
                    >
                        {/* @DESC:: First item @habib610 Tue November 19,2024 */}
                        <div>
                            <div className="  lg:hidden py-0.5 sm:py-0 ">
                                {showBack ? (
                                    <button
                                        onClick={() => history.goBack()}
                                        className="text-white text-xxl"
                                    >
                                        <IoChevronBack />
                                    </button>
                                ) : (
                                    brand
                                )}
                            </div>
                            <div className="hidden lg:flex lg:flex-1 py-0.5 sm:py-0">
                                {brand}
                            </div>
                        </div>

                        {/* @DESC:: Second part @habib610 Tue November 19,2024 */}
                        {loggedIn ? (
                            <div className="hidden lg:flex justify-between gap-2  2xl:gap-3.2">
                                {data.map((item) => {
                                    let isCurrent = pathname
                                        .toLowerCase()
                                        .includes(item.name.toLowerCase());
                                    return (
                                        <div
                                            key={item.id}
                                            className="flex gap-1 items-center hover:cursor-pointer"
                                            role="button"
                                            onClick={() => {
                                                code
                                                    ? history.push(
                                                          `${item.path}?code=${code}`
                                                      )
                                                    : history.push(item.path);
                                            }}
                                        >
                                            <div
                                                className={cn("h-[24px]  ", {
                                                    "text-white":
                                                        !isVisible &&
                                                        pathname.includes(
                                                            LEASE_HOME
                                                        ),
                                                    "text-primary":
                                                        isCurrent &&
                                                        (!pathname.includes(
                                                            LEASE_HOME
                                                        ) ||
                                                            isVisible),
                                                    "font-bold":
                                                        isCurrent &&
                                                        pathname.includes(
                                                            LEASE_HOME
                                                        ),
                                                })}
                                            >
                                                {item.icon}
                                            </div>

                                            <P
                                                className={cn({
                                                    "text-white":
                                                        !isVisible &&
                                                        pathname.includes(
                                                            LEASE_HOME
                                                        ),
                                                    "text-primary":
                                                        isCurrent &&
                                                        (!pathname.includes(
                                                            LEASE_HOME
                                                        ) ||
                                                            isVisible),
                                                    "font-bold":
                                                        isCurrent &&
                                                        pathname.includes(
                                                            LEASE_HOME
                                                        ),
                                                })}
                                            >
                                                {item.name}
                                            </P>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className="hidden xl:flex  lg:gap-x-12 items-center ">
                                {unAuthenticLinks.map((item, index) =>
                                    item?.dropdown ? (
                                        <HeaderMenu
                                            key={index}
                                            label={item.dropdown}
                                            links={item.links}
                                            isVisible={isVisible}
                                            isAuth={isAuth}
                                        />
                                    ) : (
                                        <Link
                                            to={item.path}
                                            key={item.name}
                                            className={`text-base font-normal leading-6  hover:scale-110 duration-150  ${
                                                isVisible
                                                    ? "text-primary"
                                                    : "text-white"
                                            } ${
                                                isAuth && "!text-gray-secondary"
                                            }`}
                                        >
                                            {item.name}
                                        </Link>
                                    )
                                )}

                                {!loggedIn && (
                                    <Button
                                        variant="primary"
                                        className="hidden lg:flex text-white"
                                        onClick={() => {
                                            history.push(LOGIN);
                                        }}
                                    >
                                        Login
                                    </Button>
                                )}
                                {loggedIn && (
                                    <Button
                                        onClick={() => handleModal()}
                                        className="!rounded-[4rem] px-5"
                                    >
                                        Enquiry
                                    </Button>
                                )}
                            </div>
                        )}

                        {/* @DESC:: Auth profile and notification icon @habib610 Tue November 19,2024 */}

                        {loggedIn && (
                            <HeaderProfileDropDown
                                onLogout={handleLogout}
                                links={[]} // dropdown links here
                                user={state?.user}
                                code={code}
                                onBurgerOpen={() =>
                                    setShowBurger((prev) => !prev)
                                }
                            />
                        )}
                        {/* @DESC:: UnAuthorized burger button for mobile @habib610 Tue November 19,2024 */}
                        {!loggedIn && (
                            <button
                                type="button"
                                className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5  flex-col duration-150  lg:hidden ${
                                    isVisible ? "text-primary" : "text-white"
                                } ${isAuth && "text-gray-secondary"}`}
                                onClick={() => setShowBurger(true)}
                            >
                                <span className="sr-only">Open main menu</span>

                                <svg
                                    className="hidden sm:block xl:hidden "
                                    width="37"
                                    height="28"
                                    viewBox="0 0 37 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.73438 2H34.7344M8.73437 14H34.7344M14.7344 26H34.7344"
                                        stroke="currentColor"
                                        strokeWidth="3.75"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <svg
                                    width="30"
                                    height="31"
                                    viewBox="0 0 30 31"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="sm:hidden"
                                >
                                    <path
                                        d="M5.00391 8.25488H25.0039M8.75391 15.7549H25.0039M12.5039 23.2549H25.0039"
                                        stroke="currentColor"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        )}

                        {/* @TODO => Fix Notification actions @habib610 Mon November 18,2024 */}
                        {/*    <div className="hidden items-center gap-1 ">
                           <button
                                onClick={() => null}
                                className="  hidden lg:flex border border-solid border-blue-400   items-center justify-center w-[37px] h-[37px] sm:w-[40px] sm:h-[40px] xl:w-[40px]  xl:h-[40px] rounded-full relative "
                            >
                                <P className="text-white lg:text-gray">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15 19.25C15 20.0456 14.6839 20.8087 14.1213 21.3713C13.5587 21.9339 12.7956 22.25 12 22.25C11.2044 22.25 10.4413 21.9339 9.87869 21.3713C9.31608 20.8087 9 20.0456 9 19.25"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M5.58096 18.25C5.09151 18.1461 4.65878 17.8626 4.36813 17.4553C4.07748 17.048 3.95005 16.5466 4.01098 16.05L5.01098 7.93998C5.2663 6.27263 6.11508 4.75352 7.40121 3.66215C8.68734 2.57077 10.3243 1.98054 12.011 1.99998C13.6977 1.98054 15.3346 2.57077 16.6207 3.66215C17.9069 4.75352 18.7557 6.27263 19.011 7.93998L20.011 16.05C20.0723 16.5452 19.9462 17.0454 19.6576 17.4525C19.369 17.8595 18.9386 18.144 18.451 18.25C14.2186 19.2445 9.81332 19.2445 5.58096 18.25Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </P>
                                <div className="w-1 h-1 bg-red top-0 right-0 absolute rounded-full border-black-600 border lg:border-gray-70"></div>
                            </button>
                        </div>  */}
                    </nav>
                </Container>
            </header>
            <Hamburger
                links={unAuthenticLinks}
                showBurger={showBurger}
                onClick={(val) => setShowBurger(val)}
                handleLogout={handleLogout}
                user={state?.user}
            />
        </div>
    );
};

export default Header;
