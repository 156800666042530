import React, { forwardRef } from "react";
import { IoMdLogOut } from "react-icons/io";
import { useHistory, useLocation } from "react-router-dom";
import { LEASE_LOGIN, LOGIN } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import withClickOutside from "../../hoc/withClickOutside";
import auth from "../../services/auth";

const HeaderProfileDropDown = forwardRef(
    (
        {
            setIsSelected,
            isSelected,
            links,
            onLogout,
            user,
            onBurgerOpen,
            code,
        },
        ref
    ) => {
        const history = useHistory();
        const { pathname } = useLocation();
        const { dispatch } = useContextState();

        const handleLogout = (e) => {
            e.preventDefault();

            auth.logout(dispatch)
                .then(() => {
                    let redirectPath = pathname.includes("lease")
                        ? `${LEASE_LOGIN}?code=${code}`
                        : LOGIN;
                    history.push(redirectPath);
                })
                .catch(() => {});
        };

        return (
            <div
                role="button"
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!isSelected) {
                        setIsSelected((prev) => !prev);
                    }
                }}
                id="dropButton"
            >
                <div className="bg-linear-yellow-green w-[37px] h-[37px] sm:w-[40px] sm:h-[40px] xl:w-[40px]  xl:h-[40px] rounded-full flex items-center justify-center text-base sm:text-xl sm:leading-[40px] font-bold leading-[35px] xl:leading-[40px] border-2 border-blue-100 relative">
                    {user?.name[0]}
                    {isSelected && (
                        <div
                            style={{ zIndex: 1110 }}
                            className="w-auto  bg-white absolute right-0 top-[40px] xl:top-5 text-nowrap  flex flex-nowrap flex-col items-start px-2 rounded py-1.6 gap-1"
                        >
                            {links.map((item, index) => (
                                <button
                                    onClick={() => {
                                        history.push(item.path);
                                        setIsSelected((prev) => !prev);
                                    }}
                                    key={index}
                                    className="text-sm md:text-base font-normal leading-6 text-gray hover:scale-110 duration-150 flex gap-0.4 items-center"
                                >
                                    {item.icon} {item.name}
                                </button>
                            ))}
                            <button
                                onClick={handleLogout}
                                className="text-sm md:text-base font-normal leading-6 text-gray hover:scale-110 duration-150 flex gap-0.4 items-center"
                            >
                                <IoMdLogOut />
                                Logout
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export default withClickOutside(HeaderProfileDropDown);
