import React from "react";
import Container from "../../../shared/Container";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
const PlantDetails = (props) => {
    return (
        <div className="bg-black-600 lg:bg-transparent pt-10.4 pb-4 lg:p-0 rounded-b-4 h-full">
            <Container className=" h-full  lg:px-0">
                <div className="flex flex-col justify-between  h-full">
                    <div className="flex items-center justify-between mb-2">
                        <div>
                            <H4 className="font-vietnam text-white lg:text-xl xl:text-xl 2xl:text-xxl">
                                {props?.data?.plantName}
                            </H4>
                            <P className="mt-0.5  font-vietnam text-gray-550">
                                {props?.data?.shortAddress}
                            </P>
                        </div>
                    </div>
                    <div className="flex rounded-sm  p-1 border-2 border-white justify-between">
                        <div>
                            <H4 className="text-white font-vietnam text-sm 2xs:text-base">
                                ₹
                                {props?.data?.consumerBill &&
                                    Number(
                                        props?.data?.consumerBill
                                    ).toLocaleString()}
                            </H4>
                            <P className="font-vietnam text-gray-550 text-xs 2xs:text-sm">
                                Bill Amount
                            </P>
                        </div>
                        <div className="h-auto w-0.2 rounded-full bg-white"></div>

                        <div>
                            <H4 className="text-white font-vietnam text-sm 2xs:text-base">
                                {props?.data?.solarPlantSize}KWp
                            </H4>
                            <P className="font-vietnam text-gray-550 text-xs 2xs:text-sm">
                                Solar Requirement
                            </P>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default PlantDetails;
