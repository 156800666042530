import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ERROR_MSG } from "../../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../../constants/contexConstant";
import {
    COMMUNITY_META_API_ENDPOINT,
    LEASE_DASHBOARD,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import withLeaseCodeCheck from "../../hoc/withLeaseCodeCheck";
import request from "../../services/request";
import Button from "../../shared/ui/Button";
import Stepper from "../../shared/ui/Stepper";
import Panels from "./Panels";
import Returns from "./Returns";
import Share from "./Share";
import Summary from "./Summary";

const SummaryAdmin = ({ code }) => {
    const { dispatch } = useContextState();
    const [planDetails, setPlanDetails] = useState({});
    const loadPlanMeta = useCallback(async () => {
        try {
            dispatch({ type: SET_LOADER });

            let res = await request.authGet({
                endpoint: `${COMMUNITY_META_API_ENDPOINT}?code=${code}`,
            });

            setPlanDetails(res?.data?.data);
            dispatch({ type: RESET_LOADER });
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
            dispatch({ type: RESET_LOADER });
        }
    }, [code, dispatch]);

    useEffect(() => {
        loadPlanMeta();
        setSubsidy(
            planDetails?.planMeta
                ? planDetails?.planMeta?.availingSubsidy
                : true
        );
        setReturns(
            planDetails?.planMeta
                ? Number(planDetails?.planMeta?.expectedReturns)
                : 20
        );
    }, [code, dispatch, loadPlanMeta]);
    const history = useHistory();
    const steps = ["Summary", "Panels", "Returns", "Share"];
    const [currentStep, setCurrentStep] = useState(0);
    const [subsidy, setSubsidy] = useState(
        planDetails?.planMeta ? planDetails?.planMeta?.availingSubsidy : true
    );
    const [returns, setReturns] = useState(
        planDetails?.planMeta
            ? Number(planDetails?.planMeta?.expectedReturns)
            : 20
    );

    const onNext = (i) => {
        setCurrentStep(i);
    };
    const SetSubsidy = (sub) => {
        setSubsidy(sub);
    };
    const SetReturns = (e) => {
        setReturns(e.target.value);
    };

    const renderContent = () => {
        switch (currentStep) {
            case 0:
                return <Summary onCreate={onNext} planDetails={planDetails} />;
            case 1:
                return (
                    <Panels
                        subsidy={subsidy}
                        onNext={onNext}
                        planDetails={planDetails}
                        SetSubsidy={SetSubsidy}
                    />
                );
            case 2:
                return (
                    <Returns
                        subsidy={subsidy}
                        SetReturns={SetReturns}
                        returns={returns}
                        planDetails={planDetails}
                        onNext={onNext}
                        dispatch={dispatch}
                        code={code}
                        loadPlanMeta={loadPlanMeta}
                    />
                );
            case 3:
                return (
                    <Share code={code}>
                        <Button
                            onClick={() =>
                                history.push(`${LEASE_DASHBOARD}?code=${code}`)
                            }
                            className="!rounded-[3rem] mt-3  w-full bg-black-600 text-white"
                        >
                            Visit Dashboard
                        </Button>
                    </Share>
                );
            default:
                return null;
        }
    };
    return (
        <div className="bg-black-600 md:bg-white w-full  pt-3 m-0 pb-0 flex flex-col lg:flex-row lg:justify-center lg:items-center ">
            <div className=" bg-white min-h-screen w-full   lg:w-2/3 xl:w-[40%] pt-5 h-full mt-5 overflow-hidden px-3 mb-0 rounded-t-[3rem]">
                <Stepper
                    steps={steps}
                    currentStep={currentStep}
                    onClick={onNext}
                />
                <div className="mt-4 bg-white">{renderContent()}</div>
            </div>
        </div>
    );
};

export default withLeaseCodeCheck(SummaryAdmin);
