import React, { useCallback, useEffect, useState } from "react";
import { ERROR_MSG } from "../../../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../../../constants/contexConstant";
import {
    COMMUNITY_META_API_ENDPOINT,
    SUMMARY_COMMUNITY_META_API_ENDPOINT,
} from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import withLeaseCodeCheck from "../../../hoc/withLeaseCodeCheck";
import request from "../../../services/request";
import ShowInterest from "./ShowInterest";
import LeaseSummary from "./summary";
import SubmitInterest from "./summary/SubmitInterest";

const LeaseInterest = ({ code }) => {
    const [active, setActive] = useState(1);

    const [invSummary, setInvSummary] = useState({});

    const [planMeta, setPlanMeta] = useState({});
    const { dispatch } = useContextState();

    const loadPlanMeta = useCallback(async () => {
        try {
            dispatch({ type: SET_LOADER });

            let res = await request.authGet({
                endpoint: `${COMMUNITY_META_API_ENDPOINT}?code=${code}`,
            });
            setPlanMeta(res?.data?.data);

            let summary = await request.authGet({
                endpoint: `${SUMMARY_COMMUNITY_META_API_ENDPOINT}?code=${code}`,
            });
            setInvSummary(summary?.data?.data);
            if (summary?.data?.data?.investmentInterestShown) setActive(3);
            else {
                setActive(1);
            }

            dispatch({ type: RESET_LOADER });
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
            dispatch({ type: RESET_LOADER });
        }
    }, [code, dispatch]);
    useEffect(() => {
        loadPlanMeta();
    }, [code, dispatch, loadPlanMeta]);

    const monthlyPayout = invSummary?.investmentInterestShown
        ? Number(
              Number(
                  (invSummary?.investmentInterestShown / 100000) *
                      planMeta?.investmentMeta?.monthlyPayoutPerLakh
              ).toFixed(1)
          ).toLocaleString()
        : 0;

    const totalPayout = invSummary?.investmentInterestShown
        ? (
              Number(
                  (invSummary?.investmentInterestShown / 100000) *
                      planMeta?.investmentMeta?.monthlyPayoutPerLakh
              ) * 60
          ).toLocaleString()
        : 0;

    return (
        <>
            {active === 1 ? (
                <ShowInterest
                    data={planMeta}
                    onClick={(val) => setActive(val)}
                />
            ) : active === 2 ? (
                <SubmitInterest
                    min={10000}
                    max={planMeta?.planMeta?.solarPlanCost}
                    code={code}
                    onLoad={loadPlanMeta}
                    investmentMeta={planMeta?.investmentMeta}
                    onUpdateInvest={() => setActive(3)}
                />
            ) : active === 3 ? (
                <LeaseSummary
                    onUpdateInvest={(val) => setActive(val)}
                    code={code}
                    monthlyPayout={monthlyPayout}
                    totalPayout={totalPayout}
                    data={planMeta}
                />
            ) : null}
        </>
    );
};

export default withLeaseCodeCheck(LeaseInterest);
