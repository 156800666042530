import React, { useCallback, useEffect, useState } from "react";

import { IoIosArrowDropleft } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { ERROR_MSG } from "../../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../../constants/contexConstant";
import { COMMUNITY_META_API_ENDPOINT } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import withLeaseCodeCheck from "../../hoc/withLeaseCodeCheck";
import { logo } from "../../resources/js/images";
import request from "../../services/request";
import H4 from "../../shared/typography/H4";
import SwitchingTabs from "../../shared/ui/tab/SwitchingTabs";
import AuthWrapper from "../signupAdmin/AuthWrapper";
import LoginFormFormik from "./LoginFormFormik";
const LoginAdmin = ({ code }) => {
    const { dispatch } = useContextState();
    const history = useHistory();
    const [planDetails, setPlanDetails] = useState({});
    const [currentTab, setCurrentTab] = useState(1);
    const [isSignUp, setIsSignUp] = useState(false);
    const [showPassword, setShowForgotPassword] = useState(false);
    const [update, setUpdate] = useState(false);

    const changeMethod = () => {
        setIsSignUp((isSignUp) => !isSignUp);
    };

    const handleForgotPasswordClick = () => {
        setShowForgotPassword(!showPassword);
    };

    const UpdatePass = () => {
        setUpdate(!update);
    };
    const loadPlanMeta = useCallback(async () => {
        try {
            dispatch({ type: SET_LOADER });

            let res = await request.get({
                endpoint: `${COMMUNITY_META_API_ENDPOINT}?code=${code}`,
            });

            setPlanDetails(res?.data?.data);
            dispatch({ type: RESET_LOADER });
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
            dispatch({ type: RESET_LOADER });
        }
    }, [code, dispatch]);
    useEffect(() => {
        loadPlanMeta();
    }, [code, dispatch, loadPlanMeta]);
    return (
        <AuthWrapper planDetails={planDetails}>
            <div className="bg-black-600 min-h-screen md:bg-transparent flex justify-center items-center h-full w-full ">
                <div className="bg-white min-h-screen md:min-h-[300px] mt-3 pt-3 px-2  md:p-1 box-border lg:px-3  flex flex-col gap-2 rounded-t-[3rem] md:rounded-xl  w-full ">
                    <div
                        role="button"
                        className=" hidden md:flex justify-center items-center"
                    >
                        <img src={logo} alt="logo" className="w-6 h-3" />
                    </div>
                    <div className="flex flex-wrap flex-row gap-2  justify-between items-start md:items-center">
                        <div className="flex gap-1 items-center ">
                            {showPassword && (
                                <div
                                    className="text-3xl  "
                                    role="button"
                                    onClick={() =>
                                        setShowForgotPassword((prev) => !prev)
                                    }
                                >
                                    <IoIosArrowDropleft />
                                </div>
                            )}
                            <div>
                                <H4 className="font-semibold mt-1 text-nowrap">
                                    {update
                                        ? "Update Your Password"
                                        : showPassword
                                        ? "Reset Your Password"
                                        : isSignUp
                                        ? "SignUp"
                                        : "Welcome Back!"}
                                </H4>
                                <p className="text-gray-secondary text-xs">
                                    {planDetails?.plantMeta?.plantName}
                                </p>
                            </div>
                        </div>
                        {!showPassword && (
                            <SwitchingTabs
                                options={["OTP", "Password"]}
                                currentTab={currentTab}
                                currentActive={"!rounded-[3rem] bg-gray"}
                                notActive={"!rounded-[3rem]"}
                                onTabClick={(index) => setCurrentTab(index)}
                                className="shadow-sm "
                                border="!rounded-[3rem] lg:!rounded-[3rem] xl:rounded-[3rem]"
                            />
                        )}
                    </div>
                    <LoginFormFormik
                        code={code}
                        currentTab={currentTab}
                        isSignUp={isSignUp}
                        changeMethod={changeMethod}
                        showForgotPassword={showPassword}
                        handleForgotPasswordClick={handleForgotPasswordClick}
                        UpdatePass={UpdatePass}
                        signUpLink={`/lease/register?code=${code}`}
                        planDetails={planDetails}
                    />
                </div>
            </div>
        </AuthWrapper>
    );
};

export default withLeaseCodeCheck(LoginAdmin);
