import React from "react";
import { leaseFaqs, leaseFaqTags } from "../../../constants/leaseFaq";
import { LEASE_INVEST } from "../../../constants/routes";
import withLeaseCodeCheck from "../../../hoc/withLeaseCodeCheck";
import cn from "../../../lib/cn";
import {
    firstLeaseSlider,
    secondLeaseSlider,
} from "../../../resources/js/images";
import { leaseHome } from "../../../resources/js/videos";
import Section from "../../../shared/Section";
import ContactUSForm from "../../home/ContactUSForm";
import EstimateSavings from "../../home/EstimateSavings";
import Faq from "../../home/Faq";
import InvestNow from "../../home/InvestNow";
import PriceSection from "../../home/Sections/PriceSection";
import SrotBenefit from "../../home/SrotBenefit";
import LeaseContent from "./LeaseContent";

const cardData = [
    {
        id: 1,
        image: firstLeaseSlider,
        heading: "Investment Plan That Suits Everyone",
        text: "Register and create a custom investment plan for your community. Here, members can earn up to 20% returns, and your society will benefit from significant savings on electricity bills.It's a win-win for everyone! Together, our small contributions can make a big difference.",
        link: LEASE_INVEST,
        btnText: "Show Interest",
    },
    {
        id: 2,
        image: secondLeaseSlider,

        heading: "Become Climate Warrior",
        text: "Members can register and explore the investment plan curated by admins. They can estimate their returns and tell us how much they are interested in investing in the project. Once we generate enough interest, we can ask all members to invest and fund the solar project",
        link: LEASE_INVEST,
        btnText: "Show Interest",
    },
];
const LeaseHome = ({ code }) => {
    return (
        <div>
            <Hero
                containerClass={
                    "mb-0 xl:mb-0 lg:mb-0 2xl:mb-0 bg-custom-gradient  "
                }
                wrapperClass=" md:mx-9 min-h-[5vh] sm:min-h-[5vh] md:max-h-[100vh]  xl:min-h-[100vh]"
                contentClass="mt-[5rem] md:pt-0 md:items-center"
                content={<LeaseContent code={code} data={null} />}
                url={leaseHome}
                type="video"
            />
            <SrotBenefit />

            <InvestNow cardData={cardData} />
            <EstimateSavings />
            <PriceSection link={LEASE_INVEST} btnText={"Show Interest"} />
            <ContactUSForm />
            <Faq faqs={leaseFaqs} tags={leaseFaqTags} />
        </div>
    );
};

export default withLeaseCodeCheck(LeaseHome);

const Hero = ({
    content,
    url,
    align,
    type,
    wrapperClass,
    containerClass,
    contentClass,
    videoClass,
}) => {
    return (
        <Section className={containerClass}>
            <div
                className={cn(
                    "relative  h-[593px]   md:h-[993px] lg:h-[603px]  xl:h-screen",
                    wrapperClass
                )}
            >
                <div
                    className={cn(
                        `absolute inset-x-0 inset-y-0  h-[593px]  md:h-[993px] lg:h-[603px] xl:h-screen flex isolate z-40`,
                        contentClass
                    )}
                >
                    {content}
                </div>
            </div>
            {type === "video" ? (
                <video
                    src={url}
                    className={cn(
                        "absolute inset-y-0 inset-x-0  h-[600px]  md:h-[993px] lg:h-[610px] xl:h-screen w-full object-cover",
                        videoClass
                    )}
                    autoPlay
                    loop
                    muted
                    style={{ zIndex: 2 }}
                />
            ) : (
                <img
                    style={{ zIndex: 2 }}
                    src={url}
                    className="absolute inset-y-0 inset-x-0 h-[593px]  md:h-[993px] lg:h-[603px] xl:h-screen w-full object-cover"
                    alt="hero"
                />
            )}
        </Section>
    );
};
