import React from "react";
import cn from "../../lib/cn";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";

const MembersDetails = ({ planDetails }) => {
    const intrestRate =
        (Number(planDetails?.investmentMeta?.investmentInterestCount) /
            Number(planDetails?.investmentMeta?.investmentInterestCount)) *
        100;
    return (
        <div
            className={cn(
                "flex justify-between items-center bg-linear-blue-white p-1 md:w-[50%] rounded-2xl mb-5 gap-2",
                {
                    "justify-center": !planDetails?.investmentMeta,
                }
            )}
        >
            {planDetails?.investmentMeta && (
                <div className="flex-col text-center items-center justify-center">
                    <H4 className="text-white font-medium">
                        {planDetails?.investmentMeta?.investmentInterestCount}
                    </H4>
                    <p className="text-sm text-white">Members Joined</p>
                </div>
            )}
            {planDetails?.planMeta && (
                <div
                    className={`bg-gray-400 w-0.1 min-h-[5rem]  max-h-[10rem]
                                            `}
                />
            )}
            <div className="flex-col text-center items-center justify-center">
                {planDetails?.investmentMeta ? (
                    <>
                        <H4 className="text-white font-medium">
                            {!isNaN(intrestRate) ? intrestRate.toFixed(2) : 0}%
                        </H4>
                        <p className="text-sm text-white">
                            Members want to Invest
                        </p>
                    </>
                ) : (
                    <P className="text-white font-medium">
                        Investment Plan is not created by admins yet
                    </P>
                )}
            </div>
        </div>
    );
};

export default MembersDetails;
