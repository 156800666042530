import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../constants/common";
import {
    REMOVE_REDIRECT_PATH,
    SET_ERROR,
} from "../../constants/contexConstant";
import { emailRegEx } from "../../constants/regularExpression";
import { useContextState } from "../../context/ContextProvider";
import { handleRedirection } from "../../helpers/routingHelper";
import { handleResentOTP } from "../../services/apiRequest";
import auth from "../../services/auth";
import Button from "../../shared/ui/Button";
import FormInput from "../../shared/ui/Form/FormInput";
import { loginWithOTPValidator } from "../../shared/ui/Form/schema";
import OTP from "../../shared/ui/OTP";

const LoginWithOTP = () => {
    const { state, dispatch } = useContextState();
    const [formData, setFormData] = useState({
        otp: "",
    });

    const [getOtp, setGetOtp] = useState(false);
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });
    };
    const history = useHistory();

    const handleGetOTP = async (values) => {
        let val = emailRegEx.test(values.username)
            ? values.username
            : `91${values.username}`;
        try {
            await auth.loginOTP({
                username: val,
            });
            setGetOtp(true);
            toast.success("OTP has been sent", {
                containerId: "otpContainer",
            });
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
        }
    };

    const handleSubmit = async (values, actions, mode) => {
        if (mode) {
            handleResentOTP(mode, values.username, () => null, dispatch);
            return;
        } else if (getOtp) {
            try {
                let err = {};

                if (getOtp) {
                    if (!formData.otp) err.otp = "Otp is Required";
                    else if (formData.otp && formData.otp.length !== 6)
                        err.otp = "Invalid OTP";
                }
                if (Object.keys(err).length > 0) {
                    setErrors(err);
                } else {
                    let val = emailRegEx.test(values.username)
                        ? values.username
                        : `91${values.username}`;
                    await auth.login({
                        username: val,
                        password: formData.otp,
                        otpEnable: true,
                    });
                    let profile = await auth.getUserProfile(dispatch);
                    let status = profile?.data?.investor_profile?.plant?.status;
                    const roles = profile?.data?.investor_profile?.roles;
                    handleRedirection(state, history, roles, status);
                    dispatch({
                        type: REMOVE_REDIRECT_PATH,
                    });
                }
            } catch (error) {
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        } else {
            handleGetOTP(values);
        }
    };
    const initialValues = {
        username: "",
    };
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={loginWithOTPValidator}
        >
            {({ isSubmitting, resetForm, values, isValid, dirty }) => (
                <Form>
                    <div className="flex flex-col gap-1 mt-3.6">
                        <FormInput
                            label={"Email/Phone Number"}
                            name={"username"}
                            type={"text"}
                        />
                        {getOtp && (
                            <OTP
                                otp={formData.otp}
                                onChange={handleChange}
                                error={errors.otp}
                                errorMessage={errors.otp}
                                handleResentOTP={(mode) =>
                                    handleSubmit(values, { resetForm }, mode)
                                }
                            />
                        )}
                    </div>
                    <Button
                        type="submit"
                        className={
                            "bg-black text-white mt-2 !rounded-[3rem] w-full"
                        }
                        disabled={isSubmitting || !(isValid && dirty)}
                        isLoading={isSubmitting}
                        loaderClass="w-2 h-2 lg:w-3 lg:h-3"
                    >
                        {getOtp ? "Login" : "Get OTP"}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default LoginWithOTP;
