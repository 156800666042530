import { Form, Formik } from "formik";
import React, { useState } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { ERROR_MSG } from "../../constants/common";
import {
    REMOVE_REDIRECT_PATH,
    SET_ERROR,
} from "../../constants/contexConstant";
import { emailRegEx } from "../../constants/regularExpression";
import {
    SUMMARY_COMMUNITY_META_API_ENDPOINT,
    UNAUTHORIZED,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import {
    checkAuthorization,
    handleRedirection,
} from "../../helpers/routingHelper";
import auth from "../../services/auth";
import request from "../../services/request";
import Button from "../../shared/ui/Button";
import FormInput from "../../shared/ui/Form/FormInput";
import { loginWithPasswordValidator } from "../../shared/ui/Form/schema";

const LoginWithEmailPassword = ({ code, planDetails }) => {
    const { state, dispatch } = useContextState();

    const [showPassword, setShowPassword] = useState(false);

    const history = useHistory();

    const handlePassword = () => {
        setShowPassword((showPassword) => !showPassword);
    };

    const handleSubmit = async (values, actions) => {
        try {
            let val = emailRegEx.test(values.username)
                ? values.username
                : `91${values.username}`;

            let reqData = {
                username: val,
                password: values.password,
                otpEnable: false,
            };

            await auth.login(reqData);

            let summary = await request.authGet({
                endpoint: `${SUMMARY_COMMUNITY_META_API_ENDPOINT}?code=${code}`,
                checkStatusCode: true,
            });

            if (summary?.status === 403) {
                await auth.logout(dispatch);
                history.push(UNAUTHORIZED);
                return;
            }
            actions.resetForm();

            let profile = await auth.getUserProfile(dispatch);
            let status = profile?.data?.investor_profile?.plant?.status;
            const roles = profile?.data?.investor_profile?.roles;
            await checkAuthorization(code, dispatch, history);
            handleRedirection(
                state,
                history,
                roles,
                status,
                code,
                planDetails,
                dispatch
            );
            dispatch({
                type: REMOVE_REDIRECT_PATH,
            });
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
        }
    };

    const initialValues = {
        password: "",
        username: "",
    };

    return (
        <Formik
            onSubmit={handleSubmit}
            validationSchema={loginWithPasswordValidator}
            initialValues={initialValues}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <div className="grid grid-cols-1  gap-1.6 lg:gap-x-[3rem] xl:gap-x-3.2  xl:mt-3.6">
                        <FormInput
                            label={"Email/Phone Number"}
                            name={"username"}
                            type={"text"}
                        />
                        <FormInput
                            label={"Password"}
                            name={"password"}
                            type={showPassword ? "text" : "password"}
                            icon={
                                showPassword ? <FaEyeSlash /> : <IoEyeSharp />
                            }
                            iconWrapperClass={
                                "right-0 px-[2rem] text-lg rounded-md bg-gray-100 my-0.1 mx-px"
                            }
                            iconChange={handlePassword}
                        />
                    </div>
                    <Button
                        type="submit"
                        className={
                            "bg-black text-white mt-2 !rounded-[3rem] w-full"
                        }
                        disabled={isSubmitting || !(isValid && dirty)}
                        isLoading={isSubmitting}
                        loaderClass="w-2 h-2 lg:w-3 lg:h-3"
                    >
                        Login
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default LoginWithEmailPassword;
