import React, { useEffect, useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory } from "react-router-dom";
import cn from "../../../lib/cn";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import {
    opacity,
    translateContainer,
    wavyHeadings,
} from "../../../shared/animation/constants/landingAnimation";
import SplitText from "../../../shared/animation/typography/SplitText";
import H3 from "../../../shared/typography/H3";
import P from "../../../shared/typography/P";
import SubHeading from "../../../shared/typography/SubHeading";
import Button from "../../../shared/ui/Button";
import SliderButton from "../../../shared/ui/Slider/SliderButton";

const cardsData = [
    {
        title: "SROT Investment",
        isInvest: true,
        list: [
            {
                name: "Expected Returns",
                value: "20% IRR",
            },
            {
                name: "Payouts",
                value: "Monthly",
            },
            {
                name: "Capital Risk",
                value: "Medium Risk",
            },

            {
                name: "Capital Volatility",
                value: "Zero Volatility",
            },
            {
                name: "Security",
                value: "Secured by Physical Solar Assets",
            },
            {
                name: "Liquidity",
                value: "NO Liquidity",
            },
        ],
    },

    {
        title: "Bank Deposit",
        list: [
            {
                name: "Expected Returns",
                value: "4-6% Annually",
            },
            {
                name: "Payouts",
                value: "On Liquidation",
            },
            {
                name: "Capital Risk",
                value: "Low Risk",
            },

            {
                name: "Capital Volatility",
                value: "Zero Volatility",
            },
            {
                name: "Security",
                value: "Secured upto 5lakhs from bank",
            },
            {
                name: "Liquidity",
                value: "Liquidate any time",
            },
        ],
    },

    {
        title: "Mutual Funds",
        list: [
            {
                name: "Expected Returns",
                value: "8-14% IRR",
            },
            {
                name: "Payouts",
                value: "On Liquidation",
            },
            {
                name: "Capital Risk",
                value: "Higher Risk",
            },

            {
                name: "Capital Volatility",
                value: "High Volatility",
            },
            {
                name: "Security",
                value: "NO Security",
            },
            {
                name: "Liquidity",
                value: "Liquidate any time",
            },
        ],
    },
];

const pricingData = [
    {
        id: 1,
        name: "Expected Returns",
        purchase: "20% IRR",
        lease: "4-6% Annually",
        loan: "8-14% IRR",
    },
    {
        id: 2,
        name: "Payouts",
        purchase: "Monthly",
        lease: "On Liquidation",
        loan: "On Liquidation",
    },
    {
        id: 3,
        name: "Capital Risk",
        purchase: "Medium Risk",
        lease: "Low Risk",
        loan: "Higher Risk",
    },
    {
        id: 4,
        name: "Capital Volatility",
        purchase: "Zero Volatility",
        lease: "Zero Volatility",
        loan: "High Volatility",
    },
    {
        id: 5,
        name: "Security",
        purchase: "Secured by Physical Solar Assets",
        lease: "Secured upto 5lakhs from bank",
        loan: "NO Security",
    },
    {
        id: 6,
        name: "Liquidity",
        purchase: "NO Liquidity",
        lease: "Liquidate any time",
        loan: "Liquidate any time",
    },
];

const PriceSection = ({ link, btnText }) => {
    const [currentSlide, setCurrentSlide] = useState(1);
    const history = useHistory();

    useEffect(() => {
        window.addEventListener("resize", () => {});
        return () => window.removeEventListener("resize", () => {});
    }, []);

    const previousSlide = () => {
        setCurrentSlide((prev) =>
            prev === 0 ? cardsData.length - 1 : prev - 1
        );
    };

    const nextSlide = () => {
        setCurrentSlide((prev) =>
            prev === cardsData.length - 1 ? 0 : prev + 1
        );
    };

    return (
        <Section className="xl:my-[150px] 2xl:my-[150px]">
            <Container>
                <div className="flex xl:hidden flex-col items-center ">
                    <div
                        initial="initial"
                        whileInView="animate"
                        variants={translateContainer}
                    >
                        <SplitText
                            variants={wavyHeadings}
                            heading="h2"
                            className=" 2xl:mb-1 xl:pr-4  2xl:pr-6"
                        >
                            Better than any deal out there !!!
                        </SplitText>
                        <div variants={opacity}>
                            <SubHeading className="text-gray-secondary xl:text-lg 2xl:text-xl  xl:pr-4 2xl:pr-6">
                                Discover the ideal plan to fuel your investments
                            </SubHeading>
                        </div>
                    </div>
                </div>
                <div className="xl:hidden mt-2">
                    <CarouselComponent
                        selectedItem={currentSlide}
                        onChange={setCurrentSlide}
                        showArrows={true}
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                        emulateTouch={true}
                        infiniteLoop={true}
                        interval={3000}
                        autoPlay={true}
                        stopOnHover={true}
                        transitionTime={500}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        swipeable={true}
                        centerMode={true}
                        centerSlidePercentage={
                            document.body.clientWidth < 600 ? 100 : 80
                        }
                    >
                        {cardsData.map((data, index) => (
                            <Card
                                key={index}
                                list={data.list}
                                title={data?.title}
                                isInvest={data?.isInvest}
                                link={link}
                                btnText={btnText}
                            />
                        ))}
                    </CarouselComponent>

                    <SliderButton
                        currentSlide={currentSlide}
                        onPrev={previousSlide}
                        onNext={nextSlide}
                        data={cardsData}
                        className={"justify-start"}
                    />
                </div>

                <div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                    className="hidden xl:flex flex-wrap mb-4"
                >
                    <div className="w-[30%] ">
                        <div className="2xl:mb-[1.3rem]  2xl:pb-px  2xl:pt-0">
                            <SplitText
                                variants={wavyHeadings}
                                heading="h2"
                                className="text-8xl  2xl:mb-1 xl:pr-4  2xl:pr-6"
                            >
                                Better than any deal out there !!!
                            </SplitText>
                            <div variants={opacity}>
                                <P className="text-gray-secondary xl:text-lg 2xl:text-xl  xl:pr-4 2xl:pr-6">
                                    You can achieve higher returns with less
                                    risk and volatility compared to other asset
                                    classes.
                                </P>
                            </div>
                        </div>
                    </div>
                    <div className="xl:w-[23%] 2xl:w-[24%] hover:cursor-pointer relative ">
                        <div className="relative xl:px-3.2 2xl:px-4">
                            <div className="text-center rounded-t-xl  flex flex-col justify-center align-center ">
                                <H3 className="text-white">SROT</H3>
                                <H3 className="text-white mb-2 xl:mb-3 2xl:mb-3.2">
                                    Investment
                                </H3>

                                <Button
                                    variant="secondary"
                                    className="w-full flex items-center justify-center text-gray-secondary"
                                    onClick={() => history.push(link)}
                                >
                                    {btnText}
                                </Button>
                            </div>
                        </div>
                        <div className="absolute rounded-3xl bg-linear-top-blue-transparent inset-x-0 xl:h-[1060px] 2xl:h-[1150px] top-0 -z-10  xl:top-[-50px] 2xl:top-[-70px]"></div>
                    </div>

                    <div className="xl:w-[23%] 2xl:w-[22%] hover:cursor-pointer">
                        <div className="flex flex-col items-center text-center bg-white  xl:py-4.8 2xl:py-6.2 xl:px-4 2xl:px-5.2 rounded-2 2xl:rounded-2.5 border border-blue-300  mx-2.4 h-full">
                            <H3 className="text-2xl font-light xl:px-3 2xl:px-4 xl:mt-2.4 2xl:mt-3">
                                Bank Deposit
                            </H3>
                        </div>
                    </div>

                    <div
                        // initial={{
                        //     scale: 1,
                        //     rotateZ: 0,
                        // }}
                        // whileHover={{
                        //     scale: 1.1,
                        //     rotateZ: "-1deg",
                        // }}
                        className="xl:w-[23%]  2xl:w-[24%] xl:px-2.4 2xl:px-4 hover:cursor-pointer"
                    >
                        <div className="flex flex-col items-center text-center bg-white  xl:py-4.8 2xl:py-6.2 xl:px-4 2xl:px-5.2 rounded-2 2xl:rounded-2.5 border border-blue-300 h-full">
                            <H3 className="text-2xl font-light xl:px-3 2xl:px-4 xl:mt-2.4 2xl:mt-3">
                                Mutual Funds
                            </H3>
                        </div>
                    </div>
                </div>
                <ul className="hidden xl:flex flex-wrap">
                    {pricingData.map((item, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={cn("  py-3 px-3 w-[30%]", {
                                    "rounded-tl-3xl rounded-bl-3xl":
                                        index % 2 !== 0,

                                    "bg-gray-100": index % 2 === 1,
                                })}
                            >
                                <li>
                                    <P> {item.name}</P>
                                </li>
                            </div>
                            <div
                                className={cn(
                                    " py-3 px-3 text-center xl:w-[23%] 2xl:w-[24%]"
                                )}
                            >
                                <li>
                                    <P className="font-medium xl:text-xl 2xl:text-[26px] 2xl:leading-[29px]">
                                        {item.purchase}
                                    </P>
                                </li>
                            </div>
                            <div
                                className={cn(
                                    "py-3 px-3 text-center xl:w-[23%] 2xl:w-[22%]",
                                    {
                                        "bg-gray-100": index % 2 === 1,
                                    }
                                )}
                            >
                                <li>
                                    <P>{item.lease}</P>
                                </li>
                            </div>
                            <div
                                className={cn(
                                    " py-3 px-3 xl:w-[23%] 2xl:w-[24%]  text-center",
                                    {
                                        "rounded-tr-3xl rounded-br-3xl":
                                            index % 2 !== 0,
                                        "bg-gray-100": index % 2 === 1,
                                    }
                                )}
                            >
                                <li>
                                    <P>{item.loan}</P>
                                </li>
                            </div>
                        </React.Fragment>
                    ))}
                </ul>
            </Container>
        </Section>
    );
};

export default PriceSection;

const Card = ({ title, list, isInvest, link, btnText }) => {
    const history = useHistory();
    return (
        <div
            className={cn(
                "w-max-[402px] mx-1 p-1.6  shadow-md border border-x-blue-50 rounded-2 overflow-hidden",
                {
                    "bg-linear-active-blue-mobile": isInvest,
                    "bg-linear-inactive": !isInvest,
                }
            )}
        >
            <div className="flex justify-between items-center mb-1.6">
                <p className="font-nunito  text-xl  text-gray">{title}</p>
                {isInvest && (
                    <Button onClick={() => history.push(link)}>
                        {btnText}
                    </Button>
                )}
            </div>
            <hr className="mb-1.6" />
            <div className={cn("flex flex-col justify-stretch")}>
                <table className="w-full text-left  text-base sm:text-lg font-nunito  font-light">
                    <tbody>
                        {list.map((item, index) => (
                            <tr key={index} className="">
                                <td className="pb-3.2   text-gray-secondary">
                                    {item.name}
                                </td>
                                <td className="pb-3.2  font-normal text-end">
                                    {item.value}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
