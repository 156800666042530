import { ROLE } from "../constants/common";
import {
    LEASE_HOME,
    LEASE_PLAN,
    UNAUTHORIZED,
    USER_HOME,
} from "../constants/routes";
import auth from "../services/auth";
import request from "../services/request";

import { checkIsAllowed } from "./helpers";

export const handleRedirection = (
    state,
    history,
    roles,
    status,
    code,
    planDetails,
    dispatch
) => {
    if (code) {
        if (
            !planDetails?.planMeta &&
            checkIsAllowed(roles, [
                ROLE.CONSUMER_ADMIN,
                ROLE.SUPER_ADMIN,
                ROLE.SALES_MANAGER,
            ])
        ) {
            history.push(`${LEASE_PLAN}?code=${code}`);
        } else {
            history.push(`${LEASE_HOME}?code=${code}`);
        }
    } else if (state.redirectPath) {
        history.push(state.redirectPath);
    } else if (
        checkIsAllowed(roles, [
            ROLE.SUPER_ADMIN,
            ROLE.SALES_MANAGER,
            ROLE.OPERATION_MANAGER,
        ])
    ) {
        history.push(USER_HOME);
    } else {
        history.push(USER_HOME);
    }
};

export const checkAuthorization = async (code, dispatch, history) => {
    try {
        let endpoint = `/community/check/authorize?code=${code}`;
        let res = await request.authGet({
            endpoint,
        });
        if (res?.status === 403) {
            await auth.logout(dispatch);
            history.push(UNAUTHORIZED);
        }
    } catch (error) {
        await auth.logout(dispatch);
        history.push(UNAUTHORIZED);
    }
};
