import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import LoadingSpinner from "../components/loading/LoadingSpinner";
import { LEASE_HOME, PROFILE } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import { getURLQueryParams } from "../helpers/helpers";
import useAuth from "../hooks/useAuth";

const PublicRoute = ({ component: Component, ...rest }) => {
    const { state, dispatch } = useContextState();
    const location = useLocation();
    const { isAuth } = useAuth(location?.pathname, dispatch);

    const code = getURLQueryParams(location, "code");

    const redirectTo = location.pathname.includes("lease")
        ? code
            ? `${LEASE_HOME}?code=${code}`
            : LEASE_HOME
        : PROFILE;

    const isAccessible = isAuth && state?.user;

    if (isAuth === null || (isAuth && state.user === null)) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }
    if (!isAccessible) {
        return <Route {...rest}>{(props) => <Component {...props} />}</Route>;
    } else return <Redirect to={redirectTo} />;
};

export default PublicRoute;
