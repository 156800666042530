import React from "react";
import { investTg } from "../../resources/js/icons";
import P from "../../shared/typography/P";

const WelcomeScreen4 = () => {
    return (
        <div className="text-center w-full">
            <img
                src={investTg}
                alt="investTg"
                className="w-full h-96 xs:h-full md:h-96 "
            />
            <P className="font-medium font-vietnam text-base mt-5">
                Invest Together
            </P>
            <p className="text-xs xs:text-base font-vietnam md:text-sm mt-1 mb-5 md:text-gray-550">
                Investors will earn up to 20% returns, and the society will
                benefit from significant savings on electricity bills.It's a
                win-win for everyone!
            </p>
        </div>
    );
};

export default WelcomeScreen4;
