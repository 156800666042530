import React, { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { ERROR_MSG } from "../../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../../constants/contexConstant";
import { COMMUNITY_META_API_ENDPOINT } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import withLeaseCodeCheck from "../../hoc/withLeaseCodeCheck";
import { logo } from "../../resources/js/images";
import request from "../../services/request";
import AuthWrapper from "./AuthWrapper";
import SignUpPage from "./SignUpPage";
import WelcomePages from "./WelcomePages";

const SignUpAdmin = ({ code }) => {
    const [activeTab, setActiveTab] = useState(0);
    const handleTab = (tab) => {
        setActiveTab(tab);
    };
    const { dispatch } = useContextState();
    const [planDetails, setPlanDetails] = useState({});

    const loadPlanMeta = useCallback(async () => {
        try {
            dispatch({ type: SET_LOADER });

            let res = await request.get({
                endpoint: `${COMMUNITY_META_API_ENDPOINT}?code=${code}`,
            });

            setPlanDetails(res?.data?.data);
            dispatch({ type: RESET_LOADER });
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
            dispatch({ type: RESET_LOADER });
        }
    }, [code, dispatch]);

    useEffect(() => {
        loadPlanMeta();
    }, [code, dispatch, loadPlanMeta]);

    const history = useHistory();
    const renderTabContent = () => {
        switch (activeTab) {
            case 0:
                return (
                    <WelcomePages
                        code={code}
                        handleSlide={handleTab}
                        planDetails={planDetails}
                    />
                );
            case 1:
                return <SignUpPage code={code} planDetails={planDetails} />;
            default:
                return null;
        }
    };
    return (
        <AuthWrapper
            planDetails={planDetails}
            heading="Get Started"
            text="Please create your account to get onboarded to our platform"
        >
            <div className=" hidden md:flex justify-center items-center h-full w-full">
                <div className=" bg-white pt-1 md:p-1 box-border h-full lg:p-3  flex flex-col gap-2 rounded-t-[3rem] md:rounded-xl  w-full ">
                    <div
                        role="button"
                        className=" flex justify-center items-center"
                    >
                        <img src={logo} alt="logo" className="w-6 h-3" />
                    </div>
                    <SignUpPage code={code} planDetails={planDetails} />
                </div>
            </div>
            <div className="flex md:hidden justify-center items-center h-full w-full">
                <div className=" bg-white h-full pt-2 mt-4 md:p-1 box-border lg:p-3  flex flex-col gap-2 rounded-t-[3rem] md:rounded-xl  w-full ">
                    {renderTabContent()}
                </div>
            </div>
        </AuthWrapper>
    );
};

export default withLeaseCodeCheck(SignUpAdmin);
