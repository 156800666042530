import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ERROR_MSG } from "../../../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../../../constants/contexConstant";
import {
    COMMUNITY_META_API_ENDPOINT,
    LEASE_INVEST,
} from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import { getCurrencyAmount } from "../../../helpers/helpers";
import cn from "../../../lib/cn";
import request from "../../../services/request";
import Container from "../../../shared/Container";
import H1 from "../../../shared/typography/H1";
import H2 from "../../../shared/typography/H2";
import H3 from "../../../shared/typography/H3";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import FundDetails from "../dashboard/FundDetails";

const LeaseContent = ({ code }) => {
    const [planMeta, setPlanMeta] = useState({});
    const { state, dispatch } = useContextState();

    const loadPlanMeta = useCallback(async () => {
        try {
            dispatch({ type: SET_LOADER });

            let res = await request.authGet({
                endpoint: `${COMMUNITY_META_API_ENDPOINT}?code=${code}`,
            });

            setPlanMeta(res?.data?.data);
            dispatch({ type: RESET_LOADER });
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
            dispatch({ type: RESET_LOADER });
        }
    }, [code, dispatch]);

    useEffect(() => {
        loadPlanMeta();
    }, [code, dispatch, loadPlanMeta]);
    const history = useHistory();

    return (
        <div className=" w-full">
            <Container className="h-full">
                <div className="h-full flex flex-col justify-center">
                    <div className=" flex justify-center flex-col items-center">
                        <H2 className="text-xxl 2xs:text-3xl  text-center text-white font-sora mt-[100px] 2xs:mt-[170px] lg:mt-0 ">
                            Small Contributions
                        </H2>
                        <H1 className=" text-xxl 2xs:text-3xl w-full text-center text-white font-sora font-bold mb-1.6 lg:mb-3 xl:mb-4">
                            Big Returns
                        </H1>
                        <P className="text-white  text-center ">
                            Join your community in investing in rooftop solar
                            panels and start earning impressive returns.
                        </P>

                        {planMeta?.planMeta ? (
                            <>
                                <Button
                                    className="mt-3 xl:mt-4"
                                    onClick={() => history.push(LEASE_INVEST)}
                                >
                                    Show Interest
                                </Button>
                                <div
                                    className={cn(
                                        "w-full flex justify-between items-center p-1.6 rounded-sm lg:mt-6 xl:mt-10.4 ",
                                        {
                                            "justify-center lg:flex-col lg:gap-y-1":
                                                !planMeta?.investmentMeta ||
                                                !planMeta?.investmentMeta
                                                    ?.investmentInterestCount >
                                                    0,
                                        }
                                    )}
                                >
                                    {planMeta?.investmentMeta &&
                                        planMeta?.investmentMeta
                                            ?.investmentInterestCount > 0 && (
                                            <>
                                                <div>
                                                    <H3 className="text-white text-sm  2xs:text-base font-medium font-vietnam ">
                                                        {
                                                            planMeta
                                                                ?.investmentMeta
                                                                ?.investmentInterestCount
                                                        }{" "}
                                                        Members
                                                    </H3>
                                                    <P className="text-xs 2xs:text-sm font-vietnam text-white">
                                                        Shown Interest
                                                    </P>
                                                </div>
                                                <div className="w-px bg-white h-auto lg:hidden"></div>
                                            </>
                                        )}
                                    <div className="hidden lg:block  lg:w-7/12 xl:w-6/12">
                                        <FundDetails
                                            className="w-full"
                                            data={planMeta}
                                        />
                                    </div>
                                    {planMeta?.investmentMeta &&
                                    planMeta?.investmentMeta
                                        ?.investmentInterestCount > 0 ? (
                                        <>
                                            <div>
                                                <H3 className="text-white  text-sm  2xs:text-base font-vietnam font-medium ">
                                                    {getCurrencyAmount(
                                                        planMeta?.investmentMeta
                                                            ?.investmentInterestRaisedAmount
                                                    )}{" "}
                                                    Lakhs
                                                </H3>
                                                <P className=" text-xs 2xs:text-sm font-vietnam text-white">
                                                    Wants to Invest
                                                </P>
                                            </div>
                                        </>
                                    ) : planMeta?.investmentMeta
                                          ?.investmentInterestCount === 0 ? (
                                        <>
                                            <H3 className="text-white  text-sm  2xs:text-base font-vietnam font-medium ">
                                                No members have shown interest!
                                            </H3>
                                        </>
                                    ) : (
                                        <H3 className="text-white  text-sm  2xs:text-base font-vietnam font-medium ">
                                            Investment Plan is not created by
                                            admins yet
                                        </H3>
                                    )}
                                </div>
                                <div className="lg:hidden">
                                    <FundDetails data={planMeta} />
                                </div>
                            </>
                        ) : (
                            <H3 className="text-white  text-sm  2xs:text-base font-vietnam font-medium mt-4 text-center bg-linear-button-reverse rounded-sm p-1 ">
                                Investment Plan is not created by admins yet
                            </H3>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default LeaseContent;
