import React from "react";
import { interest } from "../../resources/js/icons";
import P from "../../shared/typography/P";

const WelcomeScreen3 = () => {
    return (
        <div className="text-center w-full">
            <img
                src={interest}
                alt="interest"
                className="w-full h-96 xs:h-full md:h-96 "
            />
            <P className="font-medium font-vietnam text-base xs:text-lg mt-5">
                Show Interest
            </P>
            <p className="text-xs xs:text-base md:text-sm font-vietnam mt-1 mb-2 xs:mb-5 md:text-gray-550">
                By telling us how much you want to invest in the project and
                help your society save up to 40% on electricity bills.
            </p>
        </div>
    );
};

export default WelcomeScreen3;
