import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { LiaTimesCircle } from "react-icons/lia";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ModalAlt from "../../../../components/modal/ModalAlt";
import { ERROR_MSG } from "../../../../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../../../../constants/contexConstant";
import { COMMUNITY_WITHDRAW_INVESTMENT_API_ENDPOINT } from "../../../../constants/routes";
import { useContextState } from "../../../../context/ContextProvider";
import { getCurrencyAmount } from "../../../../helpers/helpers";
import { cancelRed } from "../../../../resources/js/icons";
import request from "../../../../services/request";
import Container from "../../../../shared/Container";
import Section from "../../../../shared/Section";
import H3 from "../../../../shared/typography/H3";
import H4 from "../../../../shared/typography/H4";
import P from "../../../../shared/typography/P";
import Button from "../../../../shared/ui/Button";
import BottomDrawer from "../../../../shared/ui/Drawer/BottomDrawer";
import FundDetails from "../../dashboard/FundDetails";

const LeaseSummary = ({
    monthlyPayout,
    totalPayout,
    data,
    code,
    onUpdateInvest,
}) => {
    const [showWithDraw, setShowWithDraw] = useState(false);
    const history = useHistory();
    const { dispatch } = useContextState();
    const handleWithDraw = async () => {
        try {
            dispatch({ type: SET_LOADER });
            await request.authPost({
                endpoint: `${COMMUNITY_WITHDRAW_INVESTMENT_API_ENDPOINT}?code=${code}`,
            });

            dispatch({ type: RESET_LOADER });
            setShowWithDraw(false);
            onUpdateInvest(1);
            toast.success("Investment has been withdrawn successfully");
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
            setShowWithDraw(false);
        }
    };
    let content = (
        <div className="flex flex-col justify-center ">
            <div className="flex justify-center">
                <img src={cancelRed} alt="cancel" className="w-7 h-7" />
            </div>
            <P className="text-sm 2xs:text-base font-medium text-center text-gray my-3.2 font-vietnam">
                Do you really want to cancel your investment interest
            </P>

            <div className="p-1 rounded-sm bg-linear-silver-blue mb-4 ">
                <P className="text-gray text-sm 2xs:text-base text-center ">
                    With your investment society will save{" "}
                    {data?.plantMeta?.savingsPercentage}% on electricity bill
                </P>
            </div>

            <div className="flex justify-between items-center gap-4">
                <Button
                    onClick={handleWithDraw}
                    className="w-full bg-transparent border border-solid border-gray rounded-full text-gray"
                >
                    Yes
                </Button>
                <Button
                    onClick={() => setShowWithDraw(false)}
                    className="bg-gray rounded-full w-full"
                >
                    No
                </Button>
            </div>
        </div>
    );

    let societySavings =
        data?.plantMeta?.consumerBill -
        (data?.planMeta?.investorPayout + data?.planMeta?.maintenanceCharges);
    return (
        <>
            <Section className="flex justify-center pb-3">
                <div className="max-w-[54rem]">
                    <div className="bg-black-600 h-13 m-0 lg:hidden"></div>
                    <div className="rounded-t-4 -mt-6 bg-white pt-4 lg:mt-5 xl:mt-10.4">
                        <Container className="h-full">
                            <H4 className=" text-sm 2xs:text-base font-medium text-gray font-vietnam mb-2 lg:mb-3">
                                Investment Summary
                            </H4>
                            <FundDetails
                                className={"mt-0"}
                                label="Investment Interest Shown"
                                data={data}
                            >
                                <div className="flex items-center justify-between gap-2 mt-2">
                                    <Button
                                        onClick={() => onUpdateInvest(1)}
                                        className="flex-1 bg-gray flex items-center gap-1 text-xs 2xs:text-sm"
                                    >
                                        Revise
                                        <P className="text-white text-base">
                                            <FaEdit />
                                        </P>
                                    </Button>
                                    <Button
                                        onClick={() => setShowWithDraw(true)}
                                        className="bg-white text-gray flex-1 text-xs 2xs:text-sm"
                                    >
                                        Withdraw
                                        <P className="text-gray text-base">
                                            <LiaTimesCircle />
                                        </P>
                                    </Button>
                                </div>
                            </FundDetails>
                        </Container>
                    </div>

                    <Container>
                        <div className="bg-green-50 py-0.5  mt-2 lg:mt-2.8 rounded-full">
                            <P className="text-green-400 text-xs 2xs:text-sm font-vietnam text-center">
                                Your Investment Return Summary
                            </P>
                        </div>

                        <div className="flex justify-between p-1.2 2xs:p-1.6 rounded-sm bg-gray-50 mb-2 border border-gray-400 lg:mb-2.8">
                            <div>
                                <P className="font-vietnam text-gray-550 text-xs ">
                                    Monthly Payout
                                </P>
                                <P className="text-gray font-medium font-vietnam mt-0.8 text-sm 2xs:text-base">
                                    ₹{monthlyPayout}
                                </P>
                            </div>
                            <div>
                                <P className="font-vietnam text-gray-550 text-xs ">
                                    Total Payout
                                </P>
                                <P className="text-gray  text-sm 2xs:text-base font-vietnam font-medium mt-0.8">
                                    ₹ {totalPayout}
                                </P>
                            </div>
                        </div>

                        <div className="flex justify-between p-1.2 2xs:p-1.6 rounded-sm bg-blue-700 my-2 xl:my-2.8 xl:px-5">
                            <div>
                                <H3 className="text-white text-sm  2xs:text-base font-medium font-vietnam xl:text-xxl 2xl:text-3xl xl:font-normal">
                                    {
                                        data?.investmentMeta
                                            ?.investmentInterestCount
                                    }
                                    {`member ${
                                        data?.investmentMeta
                                            ?.investmentInterestCount &&
                                        data?.investmentMeta
                                            ?.investmentInterestCount > 1
                                            ? "s"
                                            : ""
                                    }`}
                                </H3>
                                <P className="text-xs 2xs:text-sm font-vietnam text-white">
                                    Shown Interest
                                </P>
                            </div>
                            <div className="w-px bg-white h-auto"></div>
                            <div>
                                <H3 className="text-white  text-sm  2xs:text-base font-vietnam font-medium xl:text-xxl 2xl:text-3xl xl:font-normal ">
                                    {getCurrencyAmount(
                                        data?.investmentMeta
                                            ?.investmentInterestRaisedAmount
                                    )}{" "}
                                </H3>
                                <P className=" text-xs 2xs:text-sm font-vietnam text-white">
                                    Wants to Invest
                                </P>
                            </div>
                        </div>

                        <div className="p-1 rounded-sm bg-linear-silver-blue xl:px-5">
                            <P className="text-sm 2xs:text-base text-gray">
                                Your society will save ₹{" "}
                                {Number(societySavings).toLocaleString()}, And
                                society will save{" "}
                                {data?.plantMeta?.savingsPercentage}% on
                                electricity bill
                            </P>
                        </div>
                    </Container>
                </div>
            </Section>
            <div className="hidden lg:block">
                <ModalAlt
                    innerTimes={false}
                    isOpen={showWithDraw}
                    onClose={() => setShowWithDraw(false)}
                    width={["540px", "540px", "540px"]}
                >
                    {content}
                </ModalAlt>
            </div>
            <BottomDrawer
                className="lg:hidden"
                isOpen={showWithDraw}
                onClick={() => setShowWithDraw(false)}
            >
                <Container className="max-w-[54rem]">{content}</Container>
            </BottomDrawer>
        </>
    );
};

export default LeaseSummary;
