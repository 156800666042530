import React, { useEffect, useState } from "react";
import { BsSend } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import ModalAlt from "../../../components/modal/ModalAlt";
import { ERROR_MSG, ROLE } from "../../../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../../../constants/contexConstant";
import { leaseFaqs, leaseFaqTags } from "../../../constants/leaseFaq";
import {
    COMMUNITY_MEMBERS_LIST_API_ENDPOINT,
    COMMUNITY_META_API_ENDPOINT,
    LEASE_PLAN,
    SUMMARY_COMMUNITY_META_API_ENDPOINT,
} from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import { checkIsAllowed } from "../../../helpers/helpers";
import withLeaseCodeCheck from "../../../hoc/withLeaseCodeCheck";
import request from "../../../services/request";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import BottomDrawer from "../../../shared/ui/Drawer/BottomDrawer";
import Faq from "../../home/Faq";
import Share from "../../summaryAdmin/Share";
import FundDetails from "./FundDetails";
import InterestBanner from "./InterestBanner";
import MemberDetails from "./MemberDetails";
import MembersTable from "./MembersTable";
import PlantDetails from "./PlantDetails";

const LeaseDashboard = ({ code }) => {
    const { state, dispatch } = useContextState();
    const [planMeta, setPlanMeta] = useState(state?.planDetails);

    const [showInterestBanner, setShowInterestBanner] = useState(true);
    const [showInvite, setShowInvite] = useState(false);

    const history = useHistory();

    let [membersList, setMembersList] = useState([]);
    const isAdmin = checkIsAllowed(state?.user?.roles, [
        ROLE.CONSUMER_ADMIN,
        ROLE.SUPER_ADMIN,
        ROLE.SALES_MANAGER,
    ]);

    useEffect(() => {
        const loadPlanMeta = async () => {
            try {
                dispatch({ type: SET_LOADER });

                let res = await request.authGet({
                    endpoint: `${COMMUNITY_META_API_ENDPOINT}?code=${code}`,
                });

                setPlanMeta(res?.data?.data);
                let summary = await request.authGet({
                    endpoint: `${SUMMARY_COMMUNITY_META_API_ENDPOINT}?code=${code}`,
                });

                if (
                    summary?.data?.data?.investmentInterestShown ||
                    summary?.data?.data?.investmentInterestShown !== 0
                )
                    setShowInterestBanner(false);

                dispatch({ type: RESET_LOADER });
            } catch (error) {
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
                dispatch({ type: RESET_LOADER });
            }
        };
        loadPlanMeta();
    }, [code, dispatch]);

    useEffect(() => {
        const loadPlantMembers = async () => {
            try {
                let res = await request.authGet({
                    endpoint: `${COMMUNITY_MEMBERS_LIST_API_ENDPOINT}?code=${code}`,
                });
                let memberData = res?.data?.data.filter(
                    (member) => member?.investmentStatus !== "PENDING"
                );
                setMembersList(memberData);
            } catch (error) {
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        };

        if (
            checkIsAllowed(state?.user?.roles, [
                ROLE.CONSUMER_ADMIN,
                ROLE.SUPER_ADMIN,
                ROLE.SALES_MANAGER,
            ])
        ) {
            loadPlantMembers();
        }
    }, [dispatch, code, state?.user?.roles]);

    let shareContent = (
        <Share code={code}>
            <Button
                onClick={() => {
                    setShowInvite(false);
                    history.push(`${LEASE_PLAN}?code=${code}`);
                }}
                className="!rounded-[3rem] mt-3  w-full bg-black-600 text-white"
            >
                Visit Community
            </Button>
        </Share>
    );

    const adminInteraction = isAdmin && (
        <div className="flex items-center justify-between gap-2 mt-2">
            <Button
                onClick={() => history.push(LEASE_PLAN)}
                className="flex-1 bg-gray flex items-center gap-1"
            >
                Revise
                <P className="text-white text-base">
                    <FaEdit />
                </P>
            </Button>
            <Button
                onClick={() => setShowInvite(true)}
                className="bg-white text-gray flex-1"
            >
                Invite{" "}
                <P className="text-gray text-base">
                    <BsSend />
                </P>
            </Button>
        </div>
    );

    return (
        <Section>
            <Container className="hidden lg:block">
                <div className="flex justify-between gap-2 2xl:gap-4 mt-10.4">
                    <div className="w-8/12 2xl:w-8/12">
                        <div className="bg-black-600 p-2.4 rounded-2 mb-4">
                            <div className="flex flex-wrap justify-between gap-y-2 ">
                                <div className=" w-full  xl:w-[42%] 2xl:w-[45%]">
                                    <PlantDetails data={planMeta?.plantMeta} />
                                </div>
                                <div className="w-full xl:w-[55%] 2xl:w-[50%]">
                                    <FundDetails
                                        label="Lease Plan Details"
                                        data={planMeta}
                                    >
                                        {adminInteraction}
                                    </FundDetails>
                                </div>
                            </div>
                        </div>

                        <Faq
                            faqs={leaseFaqs}
                            tags={leaseFaqTags}
                            className="pt-0 xl:pt-5 rounded-t-2 shadow-md"
                        />
                    </div>
                    <div className="w-4/12 2xl:w-4/12">
                        <MemberDetails data={planMeta} />
                        {showInterestBanner && <InterestBanner />}
                        {isAdmin && (
                            <MembersTable members={membersList} code={code} />
                        )}
                    </div>
                </div>
            </Container>

            <div className="lg:hidden">
                <PlantDetails data={planMeta?.plantMeta} />
                <Container>
                    <FundDetails
                        label="Lease Plan Details"
                        className={"-mt-2.4 mb-2.4"}
                        data={planMeta}
                    >
                        {adminInteraction}
                    </FundDetails>
                </Container>

                {showInterestBanner && <InterestBanner />}

                <MemberDetails members={membersList} data={planMeta} />
                <Faq faqs={leaseFaqs} tags={leaseFaqTags} />
            </div>

            <div className="hidden lg:block">
                <ModalAlt
                    width={["100%", "600px", "600px"]}
                    innerTimes={false}
                    isOpen={showInvite}
                    onClose={() => setShowInvite(false)}
                >
                    {shareContent}
                </ModalAlt>
            </div>
            <div className="lg:hidden">
                <BottomDrawer
                    isOpen={showInvite}
                    onClick={() => setShowInvite(false)}
                >
                    <Container>{shareContent}</Container>
                </BottomDrawer>
            </div>
        </Section>
    );
};

export default withLeaseCodeCheck(LeaseDashboard);
