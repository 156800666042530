import React from "react";
import { useHistory } from "react-router-dom";
import cn from "../../lib/cn";
import { error404 } from "../../resources/js/icons";
import { notFound } from "../../resources/js/images";
import H1 from "../../shared/typography/H1";
import H3 from "../../shared/typography/H3";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";

const NotFoundPage = () => {
    const history = useHistory();
    return (
        <div className="h-full pt-5">
            <img
                style={{ zIndex: 2 }}
                src={notFound}
                className="absolute inset-y-0 min-h-screen inset-x-0  w-full object-cover"
                alt="hero"
            />
            <div
                className={cn(
                    `absolute inset-x-0 inset-y-0 min-h-screen  md:min-h-full flex justify-center  text-center isolate z-40`
                )}
            >
                <div className="md:flex md:justify-center md:items-center md:gap-5">
                    <div className="flex flex-col items-center pt-10.4">
                        <H3 className="text-white pb-2 lg:text-3xl 2xl:text-4xl  font-vietnam">
                            Wow! this is unexpected
                        </H3>

                        <H1 className="text-white text-7xl  font-bold font-vietnam">
                            404
                        </H1>
                        <P className="text-white text-lg  lg:text-2xl xl:text-2xl font-vietnam 2xl:text-3xl">
                            Page Not Found!
                        </P>
                        <Button
                            className="!rounded-full mt-2 bg-transparent border border-solid px-2 lg:px-4"
                            onClick={() => history.goBack()}
                        >
                            Go Back
                        </Button>
                    </div>
                    <div className="flex flex-col items-center">
                        <img
                            src={error404}
                            alt="found a head"
                            className=" w-[200px]  sm:w-96 md:w-full"
                        />
                        <P className="text-white text-lg  lg:text-2xl xl:text-xxl font-vietnam 2xl:text-3xl">
                            But...You Found a Head
                        </P>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
