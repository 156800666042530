import React, { useState } from "react";

import { LANDING, SIGNUP } from "../../constants/routes";

import { IoIosArrowDropleft } from "react-icons/io";
import { Link } from "react-router-dom";
import { logo } from "../../resources/js/images";
import H4 from "../../shared/typography/H4";
import SwitchingTabs from "../../shared/ui/tab/SwitchingTabs";
import AuthWrapper from "../signup/AuthWrapper";
import LoginFormFormik from "./LoginFormFormik";

const Login = () => {
    const [currentTab, setCurrentTab] = useState(1);
    const [isSignUp, setIsSignUp] = useState(false);
    const [showPassword, setShowForgotPassword] = useState(false);
    const [update, setUpdate] = useState(false);

    const changeMethod = () => {
        setIsSignUp((isSignUp) => !isSignUp);
    };

    const handleForgotPasswordClick = () => {
        setShowForgotPassword(!showPassword);
    };

    const UpdatePass = () => {
        setUpdate(!update);
    };
    return (
        <AuthWrapper>
            <div className="flex justify-center items-center h-full w-full ">
                <div className="bg-white pt-3 md:p-1 box-border lg:px-3  flex flex-col gap-2 rounded-xl  w-full ">
                    <div className="flex justify-center items-center">
                        <Link
                            to={LANDING}
                            className="duration-500 transition-all"
                        >
                            <span className="sr-only">Hypersrot </span>
                            <img
                                src={logo}
                                className={` h-[20px] sm:h-[30px] w-auto transition-all ease-in-out duration-500`}
                                alt="HyperSrot_logo"
                            ></img>
                        </Link>
                    </div>
                    <div
                        role="button"
                        className=" text-3xl xl:font-light"
                        onClick={() => setShowForgotPassword((prev) => !prev)}
                    >
                        <IoIosArrowDropleft />
                    </div>

                    <div className="flex flex-wrap flex-row gap-2  justify-between items-start md:items-center">
                        <H4 className="font-semibold mt-1 text-nowrap">
                            {update
                                ? "Update Your Password"
                                : showPassword
                                ? "Reset Your Password"
                                : isSignUp
                                ? "SignUp"
                                : "Welcome Back!"}
                        </H4>
                        {!showPassword && (
                            <SwitchingTabs
                                options={["OTP", "Password"]}
                                currentTab={currentTab}
                                currentActive={"!rounded-[3rem] bg-gray"}
                                notActive={"!rounded-[3rem]"}
                                onTabClick={(index) => setCurrentTab(index)}
                                className="shadow-sm "
                                border="!rounded-[3rem] lg:!rounded-[3rem] xl:rounded-[3rem]"
                            />
                        )}
                    </div>
                    <LoginFormFormik
                        currentTab={currentTab}
                        isSignUp={isSignUp}
                        changeMethod={changeMethod}
                        showForgotPassword={showPassword}
                        handleForgotPasswordClick={handleForgotPasswordClick}
                        UpdatePass={UpdatePass}
                        signUpLink={SIGNUP}
                    />
                </div>
            </div>
        </AuthWrapper>
    );
};

export default Login;
