import React from "react";
import cn from "../../../lib/cn";
import P from "../../typography/P";
/* @TODO => fix responsive fonts sizes @habib610 Thu October 10,2024 */
const LabeledInput = ({
    label,
    required,
    errorMessage,
    labelClass,
    className,
    wrapperClassName,
    icon,
    iconChange,
    iconWrapperClass,
    ...rest
}) => {
    return (
        <div className={cn("mb-2 2xl:mb-3.2", wrapperClassName)}>
            <div
                className={cn(
                    "relative border border-blue-50 rounded-sm px-2 py-1.5 md:py-1.5 focus-within:border-primary duration-300 box-border",

                    className,
                    {
                        "border-red": errorMessage,
                    }
                )}
            >
                {label && (
                    <label
                        className={cn(
                            "text-xs lg:text-sm font-vietnam absolute left-1.8 -top-1 bg-white px-0.8 flex items-center gap-0.5",
                            labelClass
                        )}
                    >
                        {label}{" "}
                        {required && <span className="text-red">*</span>}
                    </label>
                )}
                <input
                    type="text"
                    className={cn(
                        `text-sm lg:text-base text-gray-secondary placeholder:text-gray-550 border-none outline-none h-full w-full transition-all duration-300 font-vietnam`,
                        {
                            "pr-3.8 lg:pr-5 ": icon,
                        }
                    )}
                    {...rest}
                />
                {icon && (
                    <div
                        className={cn(
                            `cursor-pointer absolute right-0 top-0 h-full px-1 lg:px-2 flex   items-center justify-center  text-gray-550 text-2xl `,
                            iconWrapperClass
                        )}
                    >
                        <span
                            onClick={() => iconChange()}
                            className={` cursor-pointer   items-center`}
                        >
                            {icon}
                        </span>
                    </div>
                )}
            </div>
            {errorMessage && (
                <P className="text-red text-xs mt-0.4">{errorMessage}</P>
            )}
        </div>
    );
};

export default LabeledInput;
