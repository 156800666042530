import React from "react";
import { PROJECTS } from "../../constants/routes";
import { mobile1, mobile2, mobile3 } from "../../resources/js/images";
import ContactUSForm from "./ContactUSForm";
import CustomerReviews from "./CustomerReviews";
import EstimateSavings from "./EstimateSavings";
import Faq from "./Faq";
import InvestNow from "./InvestNow";
import CustomerFeedbackVideo from "./Sections/CustomerFeedbackVideo";
import HeroContent from "./Sections/HeroContent";
import InsightCarouselSection from "./Sections/InsightCarouselSection";
import PriceSection from "./Sections/PriceSection";
import SolarProjects from "./SolarProjects";
import SrotBenefit from "./SrotBenefit";

const cardData = [
    {
        id: 1,
        image: mobile1,
        heading: "Sign Up and Get Started",
        text: "Begin your investment journey by signing up and completing your KYC process. You'll need to verify your PAN card details, validate your Aadhaar number, and confirm your bank account details. This bank account will be used to receive your monthly payouts.",
        link: { PROJECTS },
        btnText: "Invest now",
    },
    {
        id: 2,
        image: mobile2,

        heading: "Explore and Invest",
        text: "Dive into the Projects section to explore and choose from various open investment opportunities. All our projects are pre-vetted, risk-assessed, and pre-leased, ensuring you can invest confidently and enjoy higher returns with lower risk.",
        link: { PROJECTS },
        btnText: "Invest now",
    },
    {
        id: 3,
        image: mobile3,
        heading: "Build Diversified Solar Portfolio",
        text: "Keep track of all your past investments through the Portfolio section, where you can view your monthly payouts and see the impact of your investments by offsetting CO2 with your solar assets. Build a diversified portfolio and contribute to a greener future.",
        link: { PROJECTS },
        btnText: "Invest now",
    },
];
const Home = () => {
    return (
        <>
            <HeroContent />
            <SrotBenefit />
            <InvestNow cardData={cardData} />
            <EstimateSavings />
            <PriceSection link={PROJECTS} btnText={" Invest now"} />
            <SolarProjects />
            <CustomerFeedbackVideo />
            <CustomerReviews />
            <ContactUSForm />
            <InsightCarouselSection />
            <Faq />
        </>
    );
};

export default Home;
