import React from "react";
import H4 from "../../shared/typography/H4";
import Button from "../../shared/ui/Button";
const Summary = ({ onCreate }) => {
    return (
        <div className="pb-5 mb-5">
            <H4 className="font-semibold">Prepare Lease Investment Plan</H4>
            <p className="text-sm text-gray font-vietnam font-medium mt-2">
                Instructions
            </p>
            <p className="text-sm my-1">
                As an admin, you can prepare an investment plan to fund your
                rooftop solar project. You have the option to decide whether to
                avail government's subsidy or not.
            </p>
            <p className="text-sm my-1">
                Additionally, you can determine the investment returns for all
                members based on their contributions.
            </p>
            <p className="text-sm font-vietnam font-medium text-gray mb-1">
                Tips
            </p>
            <ol className="!list-decimal list-inside ml-2 ">
                <li className="text-sm !list-decimal text-gray-secondary my-0.5">
                    <span className="font-bold">Subsidy:</span> To avail of the
                    subsidy, you must select DCR panels, which will increase the
                    initial investment cost.
                </li>
                <li className="text-sm !list-decimal text-gray-secondary my-0.5">
                    <span className="font-bold">Returns:</span> You can set
                    returns between 10% and 24%. Higher returns will reduce the
                    society's savings on electricity bills. Aim to find an
                    optimal balance between returns and savings.
                </li>
            </ol>
            <Button
                onClick={() => onCreate(1)}
                className="!rounded-[3rem] mt-4   w-full bg-black-600 text-white"
            >
                Create Lease Plan
            </Button>
        </div>
    );
};

export default Summary;
