import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
    RESET_LOADER,
    SET_LEASE_CODE,
    SET_LOADER,
} from "../constants/contexConstant";
import { COMMUNITY_CODE_VALIDATION_API_ENDPOINT } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import { getURLQueryParams } from "../helpers/helpers";
import { errorInfo } from "../resources/js/icons";
import { codeBg } from "../resources/js/images";
import request from "../services/request";
import P from "../shared/typography/P";
import OTP from "../shared/ui/OTP";

const withLeaseCodeCheck = (WrappedComponent) => {
    return (props) => {
        const { state, dispatch } = useContextState();
        const location = useLocation();
        const code = getURLQueryParams(location, "code");
        let history = useHistory();

        const [inputCode, setInputCode] = useState("");
        const [inputError, setInputError] = useState("");
        const [isCodeValidated, setCodeValidated] = useState(false);

        const validateLeaseCode = useCallback(
            async (code) => {
                try {
                    dispatch({ type: SET_LOADER });
                    let endpoint = `${COMMUNITY_CODE_VALIDATION_API_ENDPOINT}?code=${code}`;
                    await request.get({ endpoint });
                    dispatch({ type: SET_LEASE_CODE, payload: code });
                    dispatch({ type: RESET_LOADER });
                    return true;
                } catch (error) {
                    dispatch({ type: RESET_LOADER });
                    return false;
                }
            },
            [dispatch]
        );

        useEffect(() => {
            const loadAndValidateCode = async () => {
                if (state?.leaseCode) {
                    setCodeValidated(true);
                    setInputCode(state?.leaseCode);
                    if (!location.pathname.includes("code")) {
                        let newPath = `${location.pathname}?code=${state?.leaseCode}`;
                        history.replace(newPath);
                    }
                } else if (!code) {
                } else if (!/^[A-Z0-9]*$/.test(code)) {
                    setInputError("Valid code is required");
                } else {
                    let isValidCode = await validateLeaseCode(code);
                    setCodeValidated(isValidCode);
                    if (isValidCode) {
                        setCodeValidated(true);
                        setInputCode(code);
                        setInputError("");
                        if (!location.pathname.includes("code")) {
                            let newPath = `${location.pathname}?code=${state?.leaseCode}`;
                            history.replace(newPath);
                        }
                    } else {
                        setInputError("Invalid code!");
                    }
                }
            };

            loadAndValidateCode();
        }, [
            code,
            history,
            location.pathname,
            state?.leaseCode,
            validateLeaseCode,
        ]);

        const handleChangeOtp = async (e) => {
            let val = e.target.value;
            setInputCode(val);

            if (!/^[A-Z0-9]*$/.test(val)) {
                setInputError(
                    "Code must be contains only uppercase letters and numbers"
                );
            } else if (val.length === 6) {
                let res = await validateLeaseCode(val);

                setCodeValidated(res);
                if (res) {
                    setInputError("");
                } else {
                    setInputError("Invalid code!");
                }
            }
        };

        if (isCodeValidated && state?.leaseCode)
            return <WrappedComponent {...props} code={inputCode} />;
        return (
            <div
                style={{
                    backgroundImage: `url(${codeBg})`,
                }}
                className="fixed inset-x-0 inset-y-0 z-101 "
            >
                <div className=" w-full h-full bg-black/40  flex items-center justify-center  ">
                    <div className="w-[100%] 2xs:w-[350px]   sm:w-[450px] bg-white p-1 2xs:p-2 md:p-4 rounded-2 min-h-[190px] box-border flex items-center justify-center flex-col ">
                        <P className="text-center font-vietnam font-medium mb-2">
                            Invalid Invite Code !!
                        </P>
                        <img
                            src={errorInfo}
                            alt="code_error"
                            className="h-5 mb-2"
                        />
                        <P className="text-xs mb-2 text-center md:text-sm lg:text-sm xl:text-base 2xl:text-base">
                            Enter invite code below, invite code is 6 character
                            long contains only letters and number characters
                            only{" "}
                        </P>
                        <OTP
                            otp={inputCode}
                            label=""
                            showResentOtp={false}
                            isInputNum={false}
                            onChange={handleChangeOtp}
                            errorMessage={inputError}
                            error={inputError}
                        />
                    </div>
                </div>
            </div>
        );
    };
};

export default withLeaseCodeCheck;
