import { climate_warrior } from "../../../../resources/js/images";
import Container from "../../../../shared/Container";
import H3 from "../../../../shared/typography/H3";
import H4 from "../../../../shared/typography/H4";
import P from "../../../../shared/typography/P";
import Button from "../../../../shared/ui/Button";

const InterestConfirmation = ({ investmentMeta, onUpdateInvest }) => {
    return (
        <Container className="  rounded-t-2">
            <div className="flex justify-center">
                <img
                    className="h-auto max-w-[400px]"
                    src={climate_warrior}
                    alt="submit_interest"
                />
            </div>

            <H4 className="text-gray font-vietnam mt-1.6 text-center font-medium text-sm 2xs:font-base ">
                Bravo !! <br />
                You are true climate warrior
            </H4>
            <div className="flex justify-between p-1.6 rounded-sm bg-linear-button my-2 text-center">
                <div>
                    <H3 className="text-white text-xl font-medium font-vietnam 2xs:text-2xl xl:text-xl 2xl:text-3xl">
                        {investmentMeta?.investmentInterestCount}
                    </H3>
                    <P className=" text-white text-xs 2xs:text-sm">
                        Members Joined
                    </P>
                </div>
                <div className="w-px bg-white h-auto"></div>
                <div>
                    <H3 className="text-white  text-xl font-vietnam font-medium 2xs:text-2xl xl:text-xl 2xl:text-3xl">
                        {investmentMeta?.societySavingPercentage}%
                    </H3>
                    <P className=" text-white text-xs 2xs:text-sm">
                        Members want to invest
                    </P>
                </div>
            </div>
            <P className="mt-1.5 mb-2 text-xs 2xs:text-sm font-vietnam">
                Invite your friends and family to invest in the solar project.
                Together, our small contributions can make a big difference.
            </P>
            <div className="flex  justify-between items-center flex-wrap gap-1">
                <div className="text-base bg-gray-400 flex-1 rounded-sm">
                    <div className=" px-1 line-clamp-1 text-xs 2xs:text-sm  py-0.8 text-primary ">
                        johndoe/referral/INVITE2345
                    </div>
                </div>
                <div className="flex items-center gap-0.5">
                    <div className="h-4 w-4 rounded-sm border-2 border-gray-550 flex items-center justify-center">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <Button
                onClick={onUpdateInvest}
                className="rounded-full bg-gray mt-2.8 w-full"
            >
                Done
            </Button>
        </Container>
    );
};

export default InterestConfirmation;
