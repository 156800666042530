import React from "react";
import { getCurrencyAmount } from "../../helpers/helpers";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import RadioInput from "../../shared/ui/Form/RadioInput";

const Panels = ({ SetSubsidy, onNext, subsidy, planDetails }) => {
    return (
        <div className="pb-5 mb-5 ">
            <div className="p-2 border border-gray-400 rounded-3xl">
                <div className="flex justify-between items-center">
                    <H4 className="font-vietnam text-sm xs:text-base font-medium">
                        {planDetails?.plantMeta?.plantName}
                    </H4>
                </div>
                <p className="text-xs text-gray-550 my-1 ">
                    {planDetails?.plantMeta?.shortAddress}
                </p>
                <div className="flex justify-between mt-3 ">
                    <div className="flex-col items-start gap-2">
                        <H4 className="font-vietnam text-sm xs:text-base font-medium">
                            ₹
                            {Number(
                                planDetails?.plantMeta?.consumerBill
                            ).toLocaleString()}
                        </H4>
                        <p className="text-gray-600 text-xs">Bill Amount</p>
                    </div>
                    <div
                        className={`bg-gray-400 w-0.1 min-h-[5rem]  max-h-[10rem]
                `}
                    />
                    <div className="flex-col items-start gap-2">
                        <H4 className="font-vietnam text-sm xs:text-base font-medium">
                            {planDetails?.plantMeta?.solarPlantSize}KWp
                        </H4>
                        <p className="text-xs text-gray-600">
                            Solar Requirement
                        </p>
                    </div>
                </div>
            </div>
            <div className="p-1 flex flex-wrap xsm:flex-nowrap  justify-between gap-0.5  sm:gap-1.5   border mt-2 border-gray-400 rounded-3xl">
                <div>
                    <P className="my-1 text-nowrap">
                        Are you availing subsidy?
                    </P>
                    <div className="flex items-center gap-2">
                        <RadioInput
                            id="governmentSubsidYes"
                            label="Yes"
                            value="Yes"
                            name="governmentSubsidy"
                            checked={subsidy}
                            activeColor="primary"
                            onChange={() => SetSubsidy(true)}
                        />

                        <RadioInput
                            id="governmentSubsidNo"
                            label="No"
                            value="No"
                            name="governmentSubsidy"
                            checked={!subsidy}
                            activeColor="primary"
                            onChange={() => SetSubsidy(false)}
                        />
                    </div>
                </div>
                <div
                    className={`bg-gray-400 w-0.1 min-h-[7rem] hidden sm:block  max-h-[10rem]
                `}
                />
                <div className="flex-col items-start gap-2 mt-1">
                    <H4 className="font-vietnam text-sm xs:text-base font-medium">
                        {subsidy
                            ? getCurrencyAmount(
                                  planDetails?.plantMeta
                                      ?.solarPlantCostPerUnitWithSubsidy
                              )
                            : getCurrencyAmount(
                                  planDetails?.plantMeta
                                      ?.solarPlantCostPerUnitWithoutSubsidy
                              )}
                    </H4>
                    <p className="text-xs text-nowrap text-gray-600">
                        Investment Required
                    </p>
                </div>
            </div>
            {subsidy && (
                <div className="bg-green-50 p-0.5 mb-3 rounded-3xl">
                    <p className="text-xs xs:text-sm font-light font-vietnam text-center text-green-400">
                        Society will receive ₹{" "}
                        {Number(planDetails?.plantMeta?.governmentSubsidy)}{" "}
                        govt. subsidy
                    </p>
                </div>
            )}
            <div className=" bg-gray-50 border border-gray-400 rounded-3xl my-2 p-2">
                <p className="font-vietnam text-xs xs:text-sm font-medium text-gray-secondary">
                    For applying govt. subsidy we need to install DCRsolar
                    panels in our plants. DCR panels cost higher compare to non
                    dcr panels
                </p>
            </div>
            <Button
                onClick={() => onNext(2)}
                className="!rounded-[3rem] my-1  w-full bg-black-600 text-white"
            >
                Create Lease Plan
            </Button>
        </div>
    );
};

export default Panels;
