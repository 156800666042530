import React from "react";
import { useHistory } from "react-router-dom";
import { hi } from "../../resources/js/icons";
import H3 from "../../shared/typography/H3";
import P from "../../shared/typography/P";
import MembersDetails from "./MembersDetails";

const WelcomeScreen1 = ({ planDetails }) => {
    const history = useHistory();
    return (
        <div className="flex-col w-full items-center  text-center">
            <div className="flex items-center justify-center">
                <img src={hi} alt="hi" className="w-8 h-8" />
            </div>
            <H3 className="font-medium">{planDetails?.plantMeta?.plantName}</H3>

            <P className="font-medium font-vietnam text-base mt-5">
                Join the Community
            </P>
            <p className="text-xs font-vietnam mb-5">
                Welcome to our app! Connect with fellow flat owners to invest in
                rooftop solar installations. Together, you can contribute to a
                sustainable future and enjoy the benefits of solar energy.
            </p>

            <MembersDetails planDetails={planDetails} />
        </div>
    );
};

export default WelcomeScreen1;
