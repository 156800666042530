import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import LoadingSpinner from "../components/loading/LoadingSpinner";
import { LEASE_LOGIN, LOGIN } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import useAuth from "../hooks/useAuth";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { state, dispatch } = useContextState();
    const location = useLocation();

    const { isAuth } = useAuth(location?.pathname, dispatch);
    const getQueryParams = (queryString) => {
        return new URLSearchParams(queryString);
    };

    const queryParams = getQueryParams(location?.search);
    const code = queryParams.get("code");
    const isAccessible = isAuth && state?.user;

    if (isAuth === null || (isAuth && state.user === null)) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }

    if (isAccessible) {
        return <Route {...rest}>{(props) => <Component {...props} />}</Route>;
    } else {
        const redirectPath = location.pathname.includes("lease")
            ? LEASE_LOGIN
            : LOGIN;

        return (
            <Redirect
                to={{
                    pathname: redirectPath,
                    search: location.pathname.includes("lease")
                        ? `?code=${code}`
                        : "",
                    state: { from: location },
                }}
            />
        );
    }
};

export default PrivateRoute;
