import React, { useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../../../constants/common";
import { RESET_LOADER, SET_LOADER } from "../../../../constants/contexConstant";
import { SAVE_COMMUNITY_INVESTMENT_API_ENDPOINT } from "../../../../constants/routes";
import { useContextState } from "../../../../context/ContextProvider";
import { submitInterest } from "../../../../resources/js/images";
import request from "../../../../services/request";
import Container from "../../../../shared/Container";
import H4 from "../../../../shared/typography/H4";
import P from "../../../../shared/typography/P";
import Button from "../../../../shared/ui/Button";
import LabeledInput from "../../../../shared/ui/Form/LabeledInput";

const SubmitContent = ({ onClick, max, min, code, onLoad }) => {
    const [amount, setAmount] = useState(50000);
    const [amountErr, setAmountErr] = useState("");
    const { dispatch } = useContextState();
    const handleChange = (e) => {
        const val = e.target.value;

        setAmount(val);
        if (isNaN(val)) {
            setAmountErr("Amount must be valid number!");
        } else if (Number(val) < min || Number(val) > max) {
            setAmountErr(`Minimum amount is ${min} and max amount is ${max}`);
        } else {
            setAmountErr("");
        }
    };
    const handleSubmit = async () => {
        if (!amountErr) {
            try {
                dispatch({ type: SET_LOADER });
                await request.authPost({
                    endpoint: `${SAVE_COMMUNITY_INVESTMENT_API_ENDPOINT}?code=${code}`,
                    body: {
                        investmentAmount: parseInt(amount),
                    },
                });

                dispatch({ type: RESET_LOADER });
                await onLoad();
                onClick();
            } catch (error) {
                toast.error(typeof error === "string" ? error : ERROR_MSG);
                dispatch({ type: RESET_LOADER });
            }
        }
    };
    return (
        <Container className="  rounded-t-2">
            <div className="flex justify-center">
                <img
                    className="h-auto max-w-[400px]"
                    src={submitInterest}
                    alt="submit_interest"
                />
            </div>

            <H4 className="text-gray font-vietnam mt-2 text-sm 2xs:text-base font-medium">
                Ready to invest? Tell us your desired amount and we’ll Keep you
                updated
            </H4>
            <P className="mt-1.5 mb-2 text-xs  font-vietnam">
                We'll provide the payment link once enough community members
                express interest in investing, just like you.
            </P>

            <LabeledInput
                errorMessage={amountErr}
                wrapperClassName="mb-1.6"
                placeholder="48,320"
                value={amount}
                onChange={handleChange}
                label="Enter amount for investment"
            />
            <Button
                disabled={amountErr}
                onClick={handleSubmit}
                className="rounded-full bg-gray mt-2.8 w-full"
            >
                Submit Interest
            </Button>
        </Container>
    );
};

export default SubmitContent;
