import React, { useEffect, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import cn from "../../../lib/cn";
import Container from "../../Container";

const BottomDrawer = ({ isOpen, onClick, className, children }) => {
    const ref = useRef();

    const handleContainerClicked = (e) => {
        if (!ref.current.contains(e.target)) {
            onClick(false);
        }
    };
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "unset";
        }
    }, [isOpen]);

    return (
        <div
            className={cn(
                "fixed left-0 bottom-0 right-0 bg-black-600/70 z-101 h-full pt-[105px] transition-transform duration-300 ease-in flex items-end w-full",
                className,
                {
                    "bottom-0 ": isOpen,
                    "top-full": !isOpen,
                }
            )}
            role="button"
            onClick={handleContainerClicked}
        >
            <div
                className={cn(
                    "w-full rounded-t-2 bg-white transition-[max-height] ease-in duration-300 overflow-hidden relative pb-2",
                    {
                        "max-h-[90vh]": isOpen,
                        "max-h-0": !isOpen,
                    }
                )}
                ref={ref}
            >
                <Container>
                    <div className="flex justify-end ">
                        <button
                            onClick={onClick}
                            className="bg-black-400/80 h-3 w-3 rounded-full flex items-center justify-center text-white text-xl my-1"
                        >
                            <FaTimes className="" />
                        </button>
                    </div>
                </Container>
                {children}
            </div>
        </div>
    );
};

export default BottomDrawer;
