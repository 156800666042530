import moment from "moment";
import React from "react";
import cn from "../../../lib/cn";
import P from "../../../shared/typography/P";

const MembersTable = ({ code, members = [] }) => {
    return (
        <div className="lg:bg-background lg:p-2 lg:rounded-t-2 lg:shadow-sm">
            <div className="flex justify-between border-b border-blue-50 ">
                {["Members Details", "Investment Amount"].map((tab, i) => (
                    <div
                        key={i}
                        className={`flex  hover:cursor-pointer items-center border-b-2 gap-1 pb-1 font-vietnam text-gray-500 border-b-transparent
                                    `}
                    >
                        <P
                            className={`text-nowrap lg:text-sm xl:text-sm 
                                                text-gray
                                        `}
                        >
                            {tab}
                        </P>
                    </div>
                ))}
            </div>
            <div
                className={cn(
                    "overflow-y-auto max-h-screen min-h-[70vh]  lg:max-h-[600px] ",
                    {
                        "flex items-center justify-center":
                            members?.length === 0,
                    }
                )}
            >
                {members?.length === 0 ? (
                    <P className="text-center">No members joined</P>
                ) : (
                    members?.map((item, index) => (
                        <div
                            key={index}
                            className="border-b border-gray-400 py-1.6 flex flex-wrap justify-between"
                        >
                            <div className="w-full">
                                <P
                                    className={cn(
                                        "!text-xs !lg:text-sm font-medium font-vietnam mb-0.5 capitalize inline-block px-1 py-0.2 rounded-full bg-green-50 text-green-400",
                                        {
                                            "bg-red-100 text-red-200":
                                                item?.investmentStatus ===
                                                "CANCELLED",

                                            "bg-orange-100 text-orange":
                                                item?.investmentStatus ===
                                                "REVISED",
                                        }
                                    )}
                                >
                                    {item?.investmentStatus.toLowerCase()}
                                </P>
                            </div>
                            <div>
                                <P className="text-gray font-medium font-vietnam mb-0.5 lg:text-sm xl:text-base 2xl:text-base">
                                    {item?.memberName}
                                </P>
                                <P className="text-gray-550 lg:text-sm xl:text-base 2xl:text-base">
                                    {item?.flatNumber}
                                </P>
                            </div>
                            <div>
                                <P className="text-gray font-medium font-vietnam mb-0.5 lg:text-sm xl:text-base 2xl:text-base">
                                    ₹{" "}
                                    {Number(
                                        item?.investmentAmount
                                    ).toLocaleString()}
                                </P>
                                <P className="text-gray-550 lg:text-sm xl:text-base 2xl:text-base">
                                    {moment(item?.investmentDate * 1000).format(
                                        "DD MMM yyyy"
                                    )}
                                </P>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default MembersTable;
