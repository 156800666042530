import { ERROR_MSG } from "../constants/common";
import { API_ENDPOINT, LANDING } from "../constants/routes";
import auth from "./auth";

const request = (() => {
    const postRequest = ({
        endpoint,
        body,
        headers = {},
        isFormData = false,
        errorData = false,
        checkStatusCode = false,
    }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const defaultHeader = {
                    "Content-Type": "application/json",
                    Accept: "*",
                };

                const response = await fetch(`${API_ENDPOINT}${endpoint}`, {
                    method: "POST",
                    headers: isFormData
                        ? headers
                        : { ...defaultHeader, ...headers },
                    body: isFormData ? body : JSON.stringify(body),
                    credentials: "same-origin",
                });
                if (checkStatusCode && response.status === 200) {
                    return resolve(response);
                }
                const data = await response.json();
                if (data?.status !== "SUCCESS") {
                    if (errorData) throw data;
                    throw data.message || ERROR_MSG;
                }
                return resolve(data);
            } catch (error) {
                return reject(error);
            }
            //TODO : add error handling
        });
    };

    const authPostRequest = async ({
        endpoint,
        body,
        headers = {},
        isFormData = false,
        errorData = false,
        checkStatusCode = false,
    }) => {
        const accessToken = await auth.getAccessToken();
        if (!accessToken) {
            window.location = LANDING;
            return;
        }
        return postRequest({
            endpoint,
            body,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                ...headers,
            },
            isFormData,
            errorData,
            checkStatusCode,
        });
    };

    const getRequest = ({
        endpoint,
        headers = {},
        checkStatusCode = false,
    }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(`${API_ENDPOINT}${endpoint}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "*",
                        ...headers,
                    },
                    credentials: "same-origin",
                });
                if (checkStatusCode) {
                    return resolve(response);
                }
                const data = await response.json();
                if (data.status !== "SUCCESS") {
                    throw data.message || ERROR_MSG;
                }
                return resolve(data);
            } catch (error) {
                return reject(error);
            }
            //TODO : add error handling
        });
    };

    const authGetRequest = async ({
        endpoint,
        headers = {},
        checkStatusCode = false,
    }) => {
        const accessToken = await auth.getAccessToken();
        if (!accessToken) {
            window.location = LANDING;
            return;
        }
        return getRequest({
            endpoint,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                ...headers,
            },
            checkStatusCode,
        });
    };

    const putRequest = ({ endpoint, body, headers = {} }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(`${API_ENDPOINT}${endpoint}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "*",
                        ...headers,
                    },
                    body: JSON.stringify(body),
                    credentials: "same-origin",
                });
                const data = await response.json();
                if (data.status !== "SUCCESS") {
                    throw data.message || ERROR_MSG;
                }
                return resolve(data);
            } catch (error) {
                return reject(error);
            }
        });
    };

    const authPutRequest = async ({ endpoint, body, headers = {} }) => {
        const accessToken = await auth.getAccessToken();
        if (!accessToken) {
            window.location = LANDING;
            return;
        }
        return putRequest({
            endpoint,
            body,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                ...headers,
            },
        });
    };

    return {
        post: postRequest,
        authPost: authPostRequest,
        get: getRequest,
        authGet: authGetRequest,
        put: putRequest,
        authPut: authPutRequest,
    };
})();

export default request;
